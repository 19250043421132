import {Fragment, useCallback, useMemo, useState} from 'react';
import Select from 'react-select';

import config from '../../config';

const dot = (color = '#ccc', size = 10) => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		backgroundColor: color,
		borderRadius: size,
		content: '" "',
		display: 'block',
		marginRight: 6,
		height: size,
		width: size,
	},
});

const colorStyles = {
	control: styles => ({...styles, backgroundColor: 'white'}),
	option: (styles, {data}) => ({...styles, ...dot(data.color)}),
	multiValueRemove: (styles) => ({...styles, cursor: 'pointer'}),
	multiValueLabel: (styles, {data}) => ({...styles, ...dot(data.color, 8)}),
};

const noOptionsMessage = () => 'Keine Radzählstationen';

const colors = config.HIGHCHARTS_OPTIONS.colors;

export default function StationSelection({stations, setSelectedStations}) {
	const [selectedOptions, setSelectedOptions] = useState(null);
	const setSelectedStationsAndOptions = useCallback((nextSelectedOptions) => {
		setSelectedOptions(nextSelectedOptions);
		setSelectedStations(nextSelectedOptions.map(option => option.value));
	}, [setSelectedStations, setSelectedOptions]);
	const options = useMemo(() => (stations || []).map(({id, title, index}) => (
		{
			value: id,
			label: title,
			color: colors[index % colors.length],
		}
	)), [stations]);
	const selectAllStations = useCallback(() => {
		setSelectedStationsAndOptions(options);
	}, [setSelectedStationsAndOptions, options]);
	const resetStations = useCallback(() => {
		setSelectedStationsAndOptions([]);
	}, [setSelectedStationsAndOptions]);

	return (
		<Fragment>
			<Select name="stations" isMulti={true} isClearable={false} options={options} styles={colorStyles}
				onChange={setSelectedStationsAndOptions} value={selectedOptions}
				placeholder="Radzählstationen auswählen …"
				noOptionsMessage={noOptionsMessage} />
			<button className="vmzhb-rz-wizard-button vmzhb-rz-wizard-button--small" type="button"
				onClick={selectAllStations}>Alle hinzufügen
			</button>
			<button className="vmzhb-rz-wizard-button vmzhb-rz-wizard-button--small" type="button"
				onClick={resetStations}>Zurücksetzen
			</button>
		</Fragment>
	);
}
