import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import getPath from "@neonaut/lib-js/es/object/getPath";

import {visibleLayersWithMiniLegendsSelector} from "@mapsight/core/lib/map/selectors";

import {MAP} from "@mapsight/ui/config/constants/controllers";
import {translate} from "@mapsight/ui/helpers/i18n";
import {setMiniLegendLayer, setOverlayModalVisible} from "@mapsight/ui/store/actions";
import {
	isOverlayModalVisibleSelector,
	miniLegendLayerIdSelector,
} from "@mapsight/ui/store/selectors";

import {LayerLegend} from "./legends";

const layersSelector = (state) =>
	visibleLayersWithMiniLegendsSelector(state[MAP]);

function MiniLegend() {
	const dispatch = useDispatch();
	const layersWithMiniLegends = useSelector(layersSelector);
	const currentKey = useSelector(miniLegendLayerIdSelector);
	const isOverlayModalVisible = useSelector(isOverlayModalVisibleSelector);

	const openBigLegend = useCallback(
		() => dispatch(setOverlayModalVisible(true)),
		[dispatch],
	);

	const onSelectChange = useCallback(
		/** @param {React.ChangeEvent<HTMLSelectElement>} e event */
		(e) => {
			dispatch(setMiniLegendLayer(e.target.value));
		},
		[dispatch],
	);

	const keys = layersWithMiniLegends && Object.keys(layersWithMiniLegends);
	if (!keys || !keys.length) {
		return null;
	}

	return (
		<div className="ms3-mini-legend">
			<div className="ms3-mini-legend__select" aria-hidden={true}>
				<select
					value={currentKey}
					onChange={onSelectChange}
					disabled={keys.length === 1}
				>
					{Object.keys(layersWithMiniLegends).map((key) => (
						<option key={key} value={key}>
							{getPath(layersWithMiniLegends[key], [
								"metaData",
								"title",
							]) || key}
						</option>
					))}
				</select>
			</div>

			<div aria-hidden={true} className="ms3-mini-legend__legend">
				<LayerLegend layerId={currentKey} variant="mini" />
			</div>

			<div className="ms3-mini-legend__more">
				<button
					className="ms3-hint--top ms3-hint--rounded"
					type="button"
					onClick={openBigLegend}
					aria-expanded={isOverlayModalVisible}
					aria-label={translate("ui.map-overlay.info.open")}
				>
					<div aria-hidden={true} style={{letterSpacing: '0.1em'}}>...</div>
				</button>
			</div>
		</div>
	);
}

export default MiniLegend;
