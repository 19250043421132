import {memo} from 'react';
import {useSelector} from 'react-redux';

import * as storeMod from '../store';

import Notifications from './notifications';

function NotificationsBody() {
	const view = useSelector(storeMod.selectView);

	switch (view) {
		case 'mobile':
			return (
				<Notifications />
			);

		case 'desktop':
			return null;

		default:
			throw new Error('Unsupported view');
	}
}

export default memo(NotificationsBody);
