import {TIME_FILTER} from '@mapsight/ui/config/constants/controllers';
import {plain} from '@mapsight/ui/config/feature/sources';

import {withFilter, xhrJson as xhr, xhrJsonRefreshing as xhrRefreshing} from '@mapsight/ui/config/feature/sources';

const geojson = a => `/geojson/${a}.geojson`;
const minutes = m => m * 60 * 1000;


export default {
	userGeolocation: plain(),
	// searchResult: plain(),

	constructionWork: xhrRefreshing(geojson('construction-work'), minutes(10)),
	constructionWorkPreview: withFilter(xhrRefreshing(geojson('construction-work-preview'), minutes(10)), TIME_FILTER),
	constructionWorkArchive: withFilter(xhr(geojson('construction-work-archive')), TIME_FILTER),
	constructionWorkDetours: withFilter(xhrRefreshing(geojson('construction-work-detours'), minutes(10)), TIME_FILTER),
	homepage: xhrRefreshing(geojson('homepage'), minutes(1)),
	traffic: xhrRefreshing(geojson('traffic'), minutes(1)),
	trafficMessagesBremenUmzu: xhrRefreshing(geojson('traffic-messages-bremen-umzu'), minutes(5)),
	trafficMessagesBremenUmzuPreview: withFilter(xhrRefreshing(geojson('traffic-messages-bremen-umzu-preview'), minutes(5)), TIME_FILTER),
	trafficMessagesAll: xhrRefreshing(geojson('traffic-messages-all'), minutes(5)),
	trafficLightFailures: xhrRefreshing(geojson('traffic-light-failures'), minutes(1)),
	parking: xhrRefreshing(geojson('parking'), minutes(2)),
	parkAndRide: xhrRefreshing(geojson('park-and-ride'), minutes(2)),
	poisWebcams: xhrRefreshing(geojson('pois-webcams'), minutes(2)),
	poisBicycleCounters: xhr(geojson('pois-bicycle-counters')),
	poisBusinessParks: xhr(geojson('pois-business-parks')),
	poisVerticalClearance: xhr(geojson('pois-vertical-clearance')),
	poisBikeAndRide: xhr(geojson('pois-bike-and-ride')),
	poisHandicappedParking: xhr(geojson('pois-handicapped-parking')),
	poisResidentParking: xhr(geojson('pois-resident-parking')),
	poisBicycleParking: xhr(geojson('pois-bicycle-parking')),
	poisTaxiParking: xhr(geojson('pois-taxi-parking')),
	poisBusParking: xhr(geojson('pois-bus-parking')),
	poisTruckParking: xhr(geojson('pois-truck-parking')),
	poisTruckInformation: xhr(geojson('pois-truck-information')),
	poisMessstellen: xhr(geojson('pois-messstellen')),
};
