import fetchPonyfill from 'fetch-ponyfill';

const {fetch} = fetchPonyfill();

const noop = () => undefined;

export default function (request, onSuccess = noop, onError = noop) {
	if (typeof request === 'string') {
		const isBrowser = typeof window !== 'undefined';

		// TODO: Fix this magic!
		request = request
			.trim()
			.replace(/^\//, isBrowser ? 'http://' + window.location.hostname + '/' : 'http://vmz.bremen.de/') // TODO!!!
			.replace(/^http:/, isBrowser ? window.location.protocol : 'http:');
	}

	fetch(request)
		.then(response => {
			if (response.status === 0 || response.ok) {
				response.text()
					.then(text => onSuccess(text))
					.catch(err => onError(err));
			} else {
				onError(response.status);
			}
		})
		.catch(err => onError(err));

	return {
		abort: function () {
			onSuccess = noop;
			onError = noop;
		},
	};
}
