import {Fragment, useCallback, useMemo, useRef, useState} from 'react';

import config from '../../config';
import {getApiUrl} from '../../helpers';

import Chart from '../Chart';

import Step from './Step';

const DATA_LIMIT = 5000;

const colors = config.HIGHCHARTS_OPTIONS.colors;

function StationTag({title, index}) {
	return (
		<span className="vmzhb-rz-station-tag">
			<i style={{backgroundColor: colors[index % colors.length]}} />
			{title}
		</span>
	);
}

export function ResultStep({active, stations, data, resolution, startDateYmd, endDateYmd, edit, stationIds}) {
	const [diagramType, setDiagramType] = useState('area');
	const handleDiagramTypeChange = useCallback(function (e) {
		setDiagramType(e.target.value);
	}, [setDiagramType]);

	let isValid = true;
	const errorMessages = [];

	if (!stationIds.length) {
		isValid = false;
		errorMessages.push('Keine Stationen ausgewählt. Bitte wählen Sie mindestens eine Station.');
	}

	if (!startDateYmd) {
		isValid = false;
		errorMessages.push('Kein Startdatum gewählt!');
	}

	if (!endDateYmd) {
		isValid = false;
		errorMessages.push('Kein Enddatum gewählt!');
	}

	if (startDateYmd && endDateYmd && startDateYmd > endDateYmd) {
		isValid = false;
		errorMessages.push('Das Enddatum liegt vor dem Startdatum!');
	}

	const downloadHref = useMemo(function () {
		return getApiUrl({
			action: 'Values',
			apiFormat: 'csv',
			resolution: resolution,
			startDate: startDateYmd,
			endDate: endDateYmd,
			stationId: stationIds,
		});
	}, [startDateYmd, endDateYmd, resolution, stationIds]);


	const tooMuchDataRef = useRef(false);
	const limitedData = useMemo(() => {
		tooMuchDataRef.current = false;

		const res = {};
		if (data && stationIds.length) {
			const perStationDataLimit = Math.floor(DATA_LIMIT / stationIds.length);
			stationIds.forEach(stationId => {
				res[stationId] = data[stationId];
				if (data[stationId].values.length > perStationDataLimit) {
					tooMuchDataRef.current = true;
					res[stationId].values = data[stationId].values.slice(0, perStationDataLimit);
				}
			});
		}
		return res;
	}, [data, stationIds]);

	return (
		<Step active={active} modifier="result">
			{isValid ? (
				<Fragment>
					<div className="vmzhb-rz-wizard-section">
						<h4 className="vmzhb-rz-wizard-section-headline">Auswahl</h4>
						<div className="vmzhb-rz-wizard-offset">
							{isValid ? (
								<Fragment>
									<strong>Gewählte Radzählstationen: </strong>
									<ul className="vmzhb-rz-station-tags">
										{
											stations
												.filter(({id}) => stationIds.includes(id))
												.map(({id, title, index}) => <StationTag key={id} title={title}
													index={index} />)
										}
									</ul>
									<strong>Gewählter Intervall: </strong> {config.resolutions[resolution].label}<br />
									<strong>Gewählter Zeitraum:: </strong> {`${startDateYmd} bis ${endDateYmd}`}<br />
								</Fragment>
							) : null}

							<button className="vmzhb-rz-wizard-button" type="button" onClick={edit}>Auswahl ändern
							</button>
						</div>
					</div>

					<div className="vmzhb-rz-wizard-section">
						<h4 className="vmzhb-rz-wizard-section-headline">Diagramm</h4>

						{tooMuchDataRef.current ? (
							<p className="vmzhb-rz-wizard-message vmzhb-rz-wizard-message--warn">Hinweis: Die
								Diagrammdarstellung
								wurde auf 5.000 Datenpunkte (über die Stationen verteilt) begrenzt.
								Bitte nutzen Sie den Download-Link um den vollständigen Datensatz herunterzuladen.</p>
						) : null}

						<div className="vmzhb-rz-wizard-chart-wrapper">
							<Chart {...{
								type: diagramType,
								showStationsList: stationIds,
								data: limitedData,
								stations: stations,
								resolution: resolution ?? 'hourly',
								truncateXAxis: true,
								showLegend: false,
								height: 320,
							}} />
						</div>

						<div
							className="vmzhb-rz-wizard-offset vmzhb-rz-wizard-offset--right vmzhb-rz-wizard-offset--border">
							<label htmlFor="vmzhb-rz-wizard-diagram-type">
								{'Diagrammtyp: '}
								<select className="vmzhb-rz-wizard-select" onChange={handleDiagramTypeChange}
									onBlur={handleDiagramTypeChange} id="vmzhb-rz-wizard-diagram-type"
									value={diagramType}>
									<option value="area">Flächendiagramm</option>
									<option value="column">Balkendiagramm*</option>
								</select>
							</label><br />

							<small>*) Bei vielen Datenpunkten ist die Darstellung als Balkendiagramm nicht
								sinnvoll.</small>
						</div>
					</div>

					<div className="vmzhb-rz-wizard-section">
						<h4 className="vmzhb-rz-wizard-section-headline">Download</h4>
						<p>
							<a href={downloadHref} target="_blank" rel="download noreferrer">Als CSV-Datei herunterladen</a>
						</p>
					</div>
				</Fragment>
			) : (
				<div className="vmzhb-rz-wizard-section">
					<h4 className="vmzhb-rz-wizard-section-headline">Ungültige Auswahl</h4>
					<p className="vmzhb-rz-wizard-message vmzhb-rz-wizard-message--error">
						{errorMessages.map(errorMessage => (
							<Fragment key={errorMessage}>{errorMessage}<br /></Fragment>))}

						<button className="vmzhb-rz-wizard-button" type="button" onClick={edit}>Zurück zur Auswahl
						</button>
					</p>
				</div>
			)}
		</Step>
	);
}
