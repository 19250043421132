export function blockMods(block, mods) {
	if (typeof block !== 'string' || typeof mods !== 'object' && typeof mods !== 'undefined') {
		throw new TypeError;
	}

	if (mods === null || typeof mods === 'undefined') {
		return block;
	}


	if (!Array.isArray(mods)) {
		mods = Object.entries(mods)
			.filter(([, v]) => !!v)
			.map(([mod]) => mod);
	}

	return mods.reduce((acc, mod) => `${acc} ${block}--${mod}`, block);
}
