import throttle from 'lodash/throttle';

/**
@param {HTMLElement} element
 * @param {string} cssVariableName
 */
function updateElementHeightInCSSVariable(element, cssVariableName) {
	const height = element.offsetHeight || 0;
	document.documentElement.style.setProperty(cssVariableName, `${height}px`);

}

function onResize() {
	updateElementHeightInCSSVariable(document.querySelector('.vmzhb-footer'), '--footer-height');
	updateElementHeightInCSSVariable(document.querySelector('.vmzhb-main-navigation'), '--main-navigation-height');
}

onResize();
window.addEventListener('resize', throttle(onResize, 200));
