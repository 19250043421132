// fetch polyfill verwenden, zB https://www.npmjs.com/package/whatwg-fetch

const table = document.querySelector('.ce-table__vba');
if (table !== null) {
	table.querySelectorAll('tr').forEach((row) => {
		function onRowClick(e) {
			if (e.target.matches('a')) {
				return;
			}

			row.removeEventListener('click', onRowClick);
			const link = row.querySelector('tr td:first-child a');
			if (!link) {
				return;
			}

			function loadTable() {
				return window.fetch(link.href, {
					cache: 'no-cache',
				})
					.then((res) => res.text())
					.then((html) => {
						const doc = new DOMParser().parseFromString(html, 'text/html');
						const tableOrig = doc.querySelector('body table table');
						return document.importNode(tableOrig, true);
					});
			}

			loadTable().then((nextTable) => {
				const td = document.createElement('td');
				td.setAttribute('colspan', '3');
				td.append(nextTable);
				const tr = document.createElement('tr');
				tr.classList.add('ce-table__vba-embedded');
				tr.append(td);
				row.parentNode.insertBefore(tr, row.nextSibling);

				window.setInterval(
					() => {
						loadTable().then((nextNextTable) => {
							while (td.firstChild) {
								td.removeChild(td.firstChild);
							}
							td.append(nextNextTable);
						});
					},
					60000
				);
			});
		}

		row.addEventListener('click', onRowClick);
	});
}
