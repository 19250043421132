export const VIEW_DESKTOP = 'desktop';
export const VIEW_MOBILE = 'mobile';

export const VIEW_FULLSCREEN = 'fullscreen';
export const VIEW_MAP_ONLY = 'mapOnly';

export const SEARCH_QUERY_PARAMETER = 'query';

export const URL_PREFIX = '/mapsight/';
export const MAPSIGHT_STORE_KEY = 'mapsight';
export const APP_ID = 'js-ms3';
export const PRESET_ELEMENT_ID = 'js-ms3-preset';

export const DETAILS_CONTENT_STATE_KEY = 'featureItemDetailsContent';
