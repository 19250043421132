// TODO: Either use external libs or merge into ../../helpers/…

import objectToQueryString from '../../helpers/object-to-query-string';
import loadJson from '../../helpers/xhr-json';

import config from './config';

export function mapFeaturesToStations(features) {
	const stations = [];

	let index = 0;
	features.forEach(feature => {
		const featureIndex = index;
		index++;
		const data = feature.properties;
		const title = data.title ? data.title.replace('Fahrradzähler', '').trim() : '';

		stations.push({
			id: data.externalId,
			title: title,
			index: featureIndex,
		});
	});

	return stations;
}

export function localTimeTimestampFromDateTimeString(str) {
	const parts = str.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);

	return Date.UTC(
		parseInt(parts[1], 10),       // year
		parseInt(parts[2], 10) - 1,   // month
		parseInt(parts[3], 10),       // day
		parseInt(parts[4], 10),       // hour
		parseInt(parts[5], 10),       // minute
		parseInt(parts[6], 10),       // seconds
		0                    // milliseconds
	);
}

export function dateToYmd(date) {
	const year = date.getFullYear();

	let month = '' + (date.getMonth() + 1);
	if (month.length < 2) {
		month = '0' + month;
	}

	let day = '' + date.getDate();
	if (day.length < 2) {
		day = '0' + day;
	}

	return [year, month, day].join('-');
}

export function formatPrettyNumber(num) {
	const str = num.toString();
	return str
		.replace(new RegExp('(^\\d{' + (str.length % 3 || -1) + '})(?=\\d{3})'), '$1.')
		.replace(/(\d{3})(?=\d)/g, '$1.');
}

export function getApiUrl(queryObject) {
	return `${config.API_URL}?${objectToQueryString(queryObject)}&cacheBust=${Date.now()}`;
}

export function queryJson(queryObject, onSuccess) {
	loadJson(getApiUrl(queryObject), onSuccess);
}

export const mapChartEntry = entry => [
	localTimeTimestampFromDateTimeString(entry[0]),
	parseInt(entry[1], 10),
];


export const getFirstDayOfMonth = (
	month = (new Date()).getMonth() + 1,
	year = (new Date()).getFullYear()
) => `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-01`;

export const getLastDayOfMonth = (
	month = (new Date()).getMonth() + 1,
	year = (new Date()).getFullYear()
) => {
	const date = new Date(year, (month - 1) + 1, 0, 12, 0, 0, 0);
	return `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const getFirstDayOfYear = (year = (new Date()).getFullYear()) => `${year.toString().padStart(4, '0')}-01-01`;
export const getLastDayOfYear = (year = (new Date()).getFullYear()) => `${year.toString().padStart(4, '0')}-12-31`;
export const getFirstDayOfLastYear = () => getFirstDayOfYear((new Date()).getFullYear() - 1);
export const getLastDayOfLastYear = () => getLastDayOfYear((new Date()).getFullYear() - 1);
