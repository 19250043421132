/* eslint-disable no-bitwise */

// FIXME document, what kind of hash this calculated and which requirements it fulfills. yes, this is a bug it using a wrong hash is a security issue, so each has to be documented
// FIXME is this replaceable with a npm? with another algorithm? are generated hashes stored in a db to keep compatibility or will rss feeds break when changing the algorithm?

export function hash(val) {
	const type = typeof val;

	if (type === 'function' || val === null || type === 'undefined') {
		return 0;
	}

	if (type === 'object') {
		return hashObject(val);
	}

	return hashString(val);
}

function hashString(str) {
	str = String(str);

	if (str.length === 0) {
		return 0;
	}

	let ret = 0;
	for (let i = 0; i < str.length; i++) {
		const char = str.charCodeAt(i);
		ret = ((ret << 5) - ret) + char;
		ret = ret & ret;
	}

	return ret;
}

function hashObject(obj) {
	let ret = 0;
	for (const k in obj) {
		if (Object.hasOwn(obj, k)) {
			ret += hashString(k) + hash(obj[k]);
		}
	}

	return ret;
}

hash.string = hashString;
hash.object = hashObject;
