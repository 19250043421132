import HighchartsReact from 'highcharts-react-official';
import {useMemo} from 'react';

import config from '../config';
import {mapChartEntry} from '../helpers';
import Highcharts from '../highcharts';

Highcharts.setOptions({
	lang: {
		months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
		shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
		weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
		numericSymbols: null, //[' tsd.', 'M', 'G', 'T', 'P', 'E'],
		printButtonTitle: 'Diagramm ausdrucken',
		resetZoomTitle: 'Zoom zurücksetzen',
		resetZoom: 'Zoom zurücksetzen',
		loading: 'Lädt...',
		exportButtonTitle: 'Diagramm sichern',
		downloadSVG: 'Als SVG sichern',
		downloadPNG: 'Als PNG sichern',
		downloadPDF: 'Als PDF sichern',
		downloadJPEG: 'Als JPEG sichern',
		decimalPoint: ',',
		thousandsSep: '.',
	},
});

/** @type {Highcharts.Options} */
const DEFAULT_STATION_OPTIONS = {
	series: [],

	colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],

	credits: {
		enabled: false,
		href: 'https://neonaut.de/',
		text: 'Neonaut GmbH',
	},

	tooltip: {
		shared: true,
		xDateFormat: '%A, %d.%m.%Y',
		borderWidth: 1,
		borderRadius: 3,
		backgroundColor: 'rgba(250, 250, 250, 0.9)',
	},

	title: {
		text: undefined,
	},
	subtitle: {
		text: undefined,
	},

	xAxis: {
		type: 'datetime',
		dateTimeLabelFormats: {
			day: '%e. %b',
			month: '%b \'%y',
		},
	},

	yAxis: {
		type: 'linear',
		min: 0,
		title: {
			text: undefined,
		},
	},

	chart: {
		style: {
			fontFamily: 'PTSansRegular, Helvetica, Arial, sans-serif',
			fontSize: '13px',
		},
		type: 'column',
		height: 480,
		backgroundColor: 'transparent',
	},

	plotOptions: {
		column: {
			pointPadding: 0.1,
			groupPadding: 0.1,
			style: 'padding: 0.1em',
		},
		area: {
			stacking: 'normal',
		},
	},

	legend: {
		enabled: true,
		layout: 'vertical',
		//itemWidth: 210,
		borderWidth: 0,
		padding: 10,
		margin: 2,
		itemStyle: {
			fontWeight: 'normal',
			fontSize: '13px',
		},
		backgroundColor: 'transparent',
		itemHiddenStyle: {
			color: '#333333',
		},
	},
};

const colors = config.HIGHCHARTS_OPTIONS.colors;

function getColorForIndex(index) {
	return colors[index % colors.length];
}

function getXDateFormat(resolution) {
	if (resolution === 'hourly') {
		return '%A, %d.%m.%Y, %H Uhr';
	}
	if (resolution === 'weekly') {
		return 'Woche vom %d.%m.%Y';
	}
	if (resolution === 'monthly') {
		return '%B %Y';
	}
	if (resolution === 'yearly') {
		return '%Y';
	}

	return '%A, %d.%m.%Y';
}

const Chart = function Chart({
	showStationsList,
	data,
	stations,
	type,
	resolution,
	height = null,
	showLegend = true,
	truncateXAxis = false,
}) {
	const options = useMemo(() => {
		const series = [];
		let earliestDateTime = null;
		let latestDateTime = null;

		if (stations && data) {
			stations.forEach(({id, title}, index) => {
				const visible = showStationsList ?
					showStationsList.indexOf(id) > -1 :
					index < config.DEFAULT_NUMBER_OF_STATIONS;

				const stationData = data[id];
				if (!stationData) {
					return;
				}

				const {values, fromDateTime, lastDateTime} = stationData;

				earliestDateTime = (earliestDateTime === null || earliestDateTime > fromDateTime) ? fromDateTime : earliestDateTime;
				latestDateTime = (latestDateTime === null || latestDateTime < lastDateTime) ? lastDateTime : latestDateTime;

				series.push({
					type: type,
					data: values.slice(0).map(mapChartEntry),
					visible: visible,
					name: title,
					index: index,
					legendIndex: index,
					color: getColorForIndex(index),
				});
			});
		}

		return ({
			...DEFAULT_STATION_OPTIONS,
			chart: {
				...DEFAULT_STATION_OPTIONS.chart,
				type: type,
				height: height !== null ? height : DEFAULT_STATION_OPTIONS.chart.height,
			},
			tooltip: {
				...DEFAULT_STATION_OPTIONS.tooltip,
				xDateFormat: getXDateFormat(resolution),
			},
			xAxis: {
				...DEFAULT_STATION_OPTIONS.xAxis,
				min: truncateXAxis ? null : (+new Date(earliestDateTime)),
				max: truncateXAxis ? null : (+new Date(latestDateTime)),
			},
			series: series,
			legend: {
				...DEFAULT_STATION_OPTIONS.legend,
				enabled: showLegend,
			},
		});
	}, [type, showStationsList, stations, data, truncateXAxis, resolution, showLegend, height]);

	return (
		<div className="vmzhb-chart">
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default Chart;
