import ReactDOM from 'react-dom';

import loadJson from '../../helpers/xhr-json';

import config from './config';
import Wizard from './components/wizard/Wizard';
import {mapFeaturesToStations} from './helpers';

let stationsLoading = false;
let stations = [];

export function render() {
	const containerElement = document.querySelector('#radzaehler-wizard');
	if (containerElement) {
		const update = () => {
			ReactDOM.render(
				<Wizard stations={stations} />,
				containerElement
			);
		};

		if (!stationsLoading) {
			stationsLoading = true;
			loadJson(config.LIST_URL, ({features}) => {
				stations = mapFeaturesToStations(features);
				update();
			});
		}

		update();
	}
}
