import PhotoSwipe from 'photoswipe';
import PhotoSwipeDefaultUI from 'photoswipe/dist/photoswipe-ui-default';

// parse slide data (url, title, size ...) from DOM elements
// (children of gallerySelector)
function parseThumbnailElements(containerElement) {
	return [...containerElement.childNodes]
		.filter(figureElement => figureElement.nodeType === 1)
		.map(figureElement => {
			const linkElement = figureElement.querySelector('a');
			const captionElement = figureElement.querySelector('figcaption');
			const thumbnailElement = figureElement.querySelector('img');

			// create slide object
			const width = parseInt(linkElement.getAttribute('data-width'), 10);
			const height = parseInt(linkElement.getAttribute('data-height'), 10);

			return {
				figureElement: figureElement,
				captionElement: captionElement,
				thumbnailElement: thumbnailElement,
				src: linkElement.getAttribute('href'),
				w: isNaN(width) ? thumbnailElement.naturalWidth : width,
				h: isNaN(height) ? thumbnailElement.naturalHeight : height,
				title: captionElement ? captionElement.innerHTML : undefined,
				msrc: thumbnailElement ? thumbnailElement.getAttribute('src') : undefined,
			};
		});
}

// find nearest parent element
function closest(el, fn) {
	return el && (fn(el) ? el : closest(el.parentNode, fn));
}

// triggers when user clicks on thumbnail
function onGalleryImageLinkClick(e, linkElement) {
	// find root element of slide
	const figureElement = closest(linkElement, el => el.tagName && el.tagName.toLowerCase() === 'figure');
	if (!figureElement) {
		return;
	}

	e.preventDefault();

	// find index of clicked item by looping through all child nodes
	// alternatively, you may define index via data- attribute
	const clickedGallery = figureElement.parentNode;

	let nodeIndex = 0;
	let index = -1;
	[...clickedGallery.childNodes]
		.filter(galleryFigureElement => galleryFigureElement.nodeType === 1)
		.some(galleryFigureElement => {
			if (galleryFigureElement === figureElement) {
				index = nodeIndex;
				return true;
			}

			nodeIndex++;
			return false;
		});

	if (index >= 0) {
		// open PhotoSwipe if valid index found
		openLightbox(index, clickedGallery);
	}
}

function openLightbox(index, galleryElement) {
	const lightboxElement = document.querySelector('.pswp');
	const items = parseThumbnailElements(galleryElement);

	// Pass data to PhotoSwipe and initialize it
	const gallery = new PhotoSwipe(lightboxElement, PhotoSwipeDefaultUI, items, {
		history: false,
		getThumbBoundsFn: i => {
			const thumbnailElement = items[i].thumbnailElement;
			const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
			const {left, top, width} = thumbnailElement.getBoundingClientRect();

			return {x: left, y: top + pageYScroll, w: width};
		},
		index: index,
	});
	gallery.listen('gettingData', (_, item) => {
		item.w = (item.w && !isNaN(item.w)) ? item.w : item.thumbnailElement.naturalWidth;
		item.h = (item.h && !isNaN(item.h)) ? item.h : item.thumbnailElement.naturalHeight;
	});
	gallery.init();
}

const GALLERY_SELECTOR = '.js-vmzhb-image-gallery';
const GALLERY_IMAGE_LINK_SELECTOR = '.js-vmzhb-image-gallery-image-link';
let globalCounter = 0;

export function render() {
	const galleryElements = [...document.querySelectorAll(GALLERY_SELECTOR)];

	// loop through all gallery elements and bind events
	galleryElements.forEach(galleryElement => {
		// skip already initialized
		if (!galleryElement.hasAttribute('data-pswp-uid')) {
			galleryElement.setAttribute('data-pswp-uid', ++globalCounter);

			[...galleryElement.querySelectorAll(GALLERY_IMAGE_LINK_SELECTOR)].forEach(linkElement => {
				linkElement.addEventListener('click', e => onGalleryImageLinkClick(e, linkElement));
			});
		}
	});
}
