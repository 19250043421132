import {useCallback, useMemo, useState} from 'react';

import {getFirstDayOfLastYear, getLastDayOfLastYear, queryJson} from '../../helpers';
import {ResultStep} from './ResultStep';

import {SelectionStep} from './SelectionStep';

const DEFAULT_RESOLUTION = 'daily';

const Wizard = function Wizard({stations}) {
	const [step, setStep] = useState(0);
	const [data, setData] = useState(null);
	const [startDateYmd, setStartDateYmd] = useState(getFirstDayOfLastYear);
	const [endDateYmd, setEndDateYmd] = useState(getLastDayOfLastYear);
	const [resolution, setResolution] = useState(DEFAULT_RESOLUTION);
	const [selectedStationsIds, setSelectedStations] = useState([]);

	const stationIds = useMemo(function () {
		return stations.map(({id}) => id).join(',');
	}, [stations]);
	const submit = useCallback((e) => {
		e.preventDefault();
		setStep(1);

		queryJson(
			{
				action: 'Values',
				apiFormat: 'json',
				resolution: resolution,
				startDate: startDateYmd,
				endDate: endDateYmd,
				stationId: stationIds,
			},
			response => setData(response),
		);
	}, [setStep, resolution, endDateYmd, startDateYmd, stationIds]);
	const edit = useCallback(() => setStep(0), [setStep]);

	return (
		<form onSubmit={submit} className="vmzhb-rz-wizard">
			<ol className="vmzhb-rz-wizard-steps">
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
				<li
					className={`vmzhb-rz-wizard-step vmzhb-rz-wizard-step--${step === 0 ? 'active' : 'inactive'}${step > 0 ? ' vmzhb-rz-wizard-step--clickable' : ''}`}
					onClick={edit}
					/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
					tabIndex={step > 0 ? 0 : null}
				>
					Auswahl
				</li>
				<li className={`vmzhb-rz-wizard-step vmzhb-rz-wizard-step--${step === 1 ? 'active' : 'inactive'}`}>
					Diagramm & Download
				</li>
			</ol>

			<div className="vmzhb-rz-wizard__content-wrapper">
				<SelectionStep
					active={step === 0} stations={stations}
					setSelectedStations={setSelectedStations}
					resolution={resolution} setResolution={setResolution}
					startDateYmd={startDateYmd} setStartDateYmd={setStartDateYmd}
					endDateYmd={endDateYmd} setEndDateYmd={setEndDateYmd} />
				<ResultStep
					active={step === 1} stations={stations}
					edit={edit} stationIds={selectedStationsIds}
					data={data} resolution={resolution}
					startDateYmd={startDateYmd} endDateYmd={endDateYmd} />
			</div>
		</form>
	);
};


export default Wizard;
