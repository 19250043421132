import {Fragment, memo, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ResizeObserver from 'resize-observer-polyfill';

import * as storeMod from '../store';

function Notifications() {
	const dispatch = useDispatch();
	const items = useSelector(storeMod.selectItems);
	const outerRef = useRef();

	useEffect(
		() => {
			const {current} = outerRef;
			const obs = new ResizeObserver((entries) => {
				dispatch(storeMod.setPlaceholderHeight(entries[0].contentRect.height));
			});
			obs.observe(current);
			return () => {
				obs.unobserve(current);
			};
		},
		[dispatch]
	);

	return (
		<div ref={outerRef}>
			{items.length ? (
				<Fragment>
					<h2 className="vmzhb-notifications__headline">Aktuelle Hinweise:</h2>
					<div className="vmzhb-notifications__text">
						<ul className="vmzhb-notifications__items">
							{items.map(entry => (
								<li className="vmzhb-notifications__item" key={entry.hash}>
									{
										entry.link ?
											<a href={entry.link}>{entry.title}</a> :
											entry.title
									}
								</li>
							))}
						</ul>
					</div>
				</Fragment>
			) : null}
		</div>
	);
}

export default memo(Notifications);
