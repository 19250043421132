import getFeatureProperty from "@mapsight/ui/helpers/get-feature-property";

/** @type import("@mapsight/ui/types").MapsightUiComponent.FeatureSelectionInfoHeader */
const FeatureSelectionInfoHeader = ({ feature }) => {
	const name = getFeatureProperty(feature, "name");
	return (
		<header className="ms3-feature-selection-info__header">
			<h2 className="ms3-feature-selection-info__headline">{name}</h2>
		</header>
	);
};

export default FeatureSelectionInfoHeader;
