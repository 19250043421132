import { APP_EVENT_PARTIAL_CONTENT_CHANGED } from "@mapsight/ui/components/helping/app-channel";
import merge from "lodash/merge";

import { siteConfig as msUiSiteConfig } from "@mapsight/ui/config";
import { FeatureSelectionsController } from "@mapsight/core/lib/feature-selections/controller";
import { FEATURE_SELECTIONS } from "@mapsight/ui/config/constants/controllers";

import createDefaultBrowserPlugins from "@mapsight/ui/plugins/browser-defaults";
import createDefaultServerPlugins from "@mapsight/ui/plugins/server-defaults";

import partialContentChangedEventPlugin from "@mapsight/ui/plugins/browser/partial-content-changed-event";
import renderAwaitFeatureDetailsLoaded from "@mapsight/ui/plugins/common/render-await-list-feature-sources-loaded";
import renderAwaitListFeatureSourcesLoaded from "@mapsight/ui/plugins/common/render-await-feature-details-loaded";

import { getDictionary } from "@mapsight/ui/helpers/i18n";

import {
	VIEW_DESKTOP,
	VIEW_FULLSCREEN,
	VIEW_MAP_ONLY,
	VIEW_MOBILE,
} from "./config/constants/app";
import base from "./config/base";

import places from "./data/places";
import regions from "./data/regions";

import TitleBar from "./components/TitleBar";
import FeatureSelectionInfoHeader from "./components/FeatureSelectionInfoHeader";
import MapOverlayEnd from "./components/MapOverlayEnd";
import MapOverlayModal from "./components/MapOverlayModal";

msUiSiteConfig.imagesUrl = "/assets/img/";

// i18n
const { de /*, en, fr, es */ } = getDictionary();
if (de) {
	de["ui.search.query-input.label"] =
		"Straße oder Ort für Suche eingeben und für Ergebnisse 2 mal Tabulator drücken.";
	de["ui.search.query-input.reset"] =
		"Suche zurücksetzen und Ergebnisliste schließen";
}

// TODO en/fr/es fehlen eh noch in i18n

function calcTopOffsetDesktop() {
	// In the browser we dynamically check the heights of main nav and notifications
	if (typeof window !== "undefined") {
		const mainNavigationElement = document.querySelector(
			".vmzhb-main-navigation"
		);
		const notificationsElement = document.querySelector(
			".vmzhb-notifications .vmzhb-area"
		);
		return (
			(mainNavigationElement?.offsetHeight || 0) +
			(notificationsElement?.offsetHeight || 0)
		);
	}

	return 95; // Otherwise fall back to default value
}

msUiSiteConfig.topOffsetForView = (view) => {
	switch (view) {
		case VIEW_FULLSCREEN:
			return 0;

		case VIEW_MOBILE:
		case VIEW_MAP_ONLY:
			return 83;

		case VIEW_DESKTOP:
		default:
			return calcTopOffsetDesktop();
	}
};

/** @type {import('@mapsight/ui/dist/types').UiState} */
const embedUiState = {
	list: {
		scrollToItemOnPreselect: true,
		selectionBehavior: {
			desktop: "scrollToMap",
			mobile: "expandInList",
		},
		stickyHeader: true,
		paginationControl: true,
		itemsPerPage: 50,
	},
	featureSelectionInfo: {
		stickyHeader: true,
	},
	layerSwitcher: {
		internal: {
			grouped: true,
		},
	},
	regions: regions,
	places: places,
};

/** @typedef {import('@mapsight/ui/types').MapsightCoreState} MapsightCoreState */
/** @typedef {import('@mapsight/ui/types').CreateOptions} CreateOptions */

/**
 * @param {MapsightCoreState} state ui create options
 * @param {{isBrowser: boolean, hook?: () => void, onPartialContentChanged?: () => void}} mapsightConfig mapsight core state
 * @returns {Parameters<import("@mapsight/ui/embed/browser").default>[1]}
 */
export default function embed(
	state,
	{ isBrowser, hook, onPartialContentChanged, renderer }
) {
	return {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		styleFunction: isBrowser ? window.__nnms3_style_function : null,
		baseMapsightConfig: merge({}, base, state),
		createOptions: {
			components: {
				TitleBar,
				FeatureSelectionInfoHeader,
				MapOverlayEnd,
				MapOverlayModal,
			},
			renderBreakpoints: {
				// NOTE: Keep in sync with css!
				mobile: [0, 900],
				desktop: [900, -1],
				tablet: undefined,
			},
			partialChangeHandler: onPartialContentChanged,
			uiState: embedUiState,
			searchUrl: "/apps/pois-search-api.php",
			plugins: [
				...(isBrowser
					? [
							...createDefaultBrowserPlugins(),
							[
								"partialContentChangedEvent",
								partialContentChangedEventPlugin(),
							],
							[
								"stickyOffset",
								{
									afterCreate:
										function partialContentChangedEventPlugin(
											context
										) {
											context.appChannelListeners.push([
												APP_EVENT_PARTIAL_CONTENT_CHANGED,
												context.createOptions
													.partialChangeHandler,
											]);
										},
								},
							],
					  ]
					: [
							...createDefaultServerPlugins(),
							[
								"renderAwaitFeatureDetailsLoaded",
								renderAwaitFeatureDetailsLoaded({}),
							],
							[
								"renderAwaitListFeatureSourcesLoaded",
								renderAwaitListFeatureSourcesLoaded({}),
							],
					  ]),
				["hook", { afterCreate: hook }],
				["corePreset", null],
				["featurePreselectDeepLink", null],
				["noScroll", null],
				["appTitle", null],
			],
			renderer: renderer,
			controllers: {
				[FEATURE_SELECTIONS]: new FeatureSelectionsController(
					FEATURE_SELECTIONS
				),
			},
		},
	};
}
