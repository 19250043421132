import {render as renderImageLoading} from './vba-a1-a27/image-loading';
import {render as renderTooltips} from './vba-a1-a27/tooltips';
import './vba-a1-a27/vba_html_script';

const SELECTOR_CLASS_NAME = 'js-vmzhb-vba-a1-a27__map';
const LOADED_DATA_ATTRIBUTE_NAME = 'data-vmzhb-vba-a1-a27-loaded';

export function render() {
	const map = document.getElementsByClassName(SELECTOR_CLASS_NAME);
	if (map.length) {
		const mapElement = map[0];

		const isLoaded = mapElement.getAttribute(LOADED_DATA_ATTRIBUTE_NAME) === 1;
		if (!isLoaded) {
			mapElement.setAttribute(LOADED_DATA_ATTRIBUTE_NAME, '1');
			window.setTimeout(() => {
				renderImageLoading(mapElement);
				renderTooltips();
			}, 0);
		}
	}
}
