const TOOLTIP_ID_PREFIX = 'vmzhb-vba-a1-a27__tooltip--';
const NORMALIZED_IMAGE_WIDTH = 900;

const X_LEFT_PKW = 350;
const X_RIGHT_PKW = 430;

const X_LEFT_LKW = 566;
const X_RIGHT_LKW = 646;

export function render() {
	let tooltipWindow;
	const diagramOsElement = document.getElementsByClassName('js-vmzhb-vba-a1-a27__traveltime-os')[0];
	const diagramHhElement = document.getElementsByClassName('js-vmzhb-vba-a1-a27__traveltime-hh')[0];

	function getNormalizedX(e) {
		const element = e.currentTarget;
		const {left, width} = element.getBoundingClientRect();
		return (e.pageX - left) / width * NORMALIZED_IMAGE_WIDTH;
	}

	function onMouseMoveOs(e) {
		const x = getNormalizedX(e);

		if (x >= X_LEFT_PKW && x <= X_RIGHT_PKW) {
			showTooltipWindow('os_pkw');
		} else if (x >= X_LEFT_LKW && x <= X_RIGHT_LKW) {
			showTooltipWindow('os_lkw');
		} else {
			hideTooltipWindow();
		}
	}

	function onMouseMoveHh(e) {
		const x = getNormalizedX(e);

		if (x >= X_LEFT_PKW && x <= X_RIGHT_PKW) {
			showTooltipWindow('hh_pkw');
		} else if (x >= X_LEFT_LKW && x <= X_RIGHT_LKW) {
			showTooltipWindow('hh_lkw');
		} else {
			hideTooltipWindow();
		}
	}

	function showTooltipWindow(id) {
		tooltipWindow = document.getElementById(TOOLTIP_ID_PREFIX + id);
		tooltipWindow.style.display = 'block';
		diagramOsElement.addEventListener('mouseout', hideTooltipWindow);
		diagramOsElement.style.cursor = 'pointer';
		diagramHhElement.addEventListener('mouseout', hideTooltipWindow);
		diagramHhElement.style.cursor = 'pointer';
		document.addEventListener('click', hideTooltipWindow);
	}

	function hideTooltipWindow() {
		if (tooltipWindow) {
			tooltipWindow.style.display = 'none';
			tooltipWindow = null;
			diagramOsElement.removeEventListener('mouseout', hideTooltipWindow);
			diagramOsElement.style.cursor = 'default';
			diagramHhElement.removeEventListener('mouseout', hideTooltipWindow);
			diagramHhElement.style.cursor = 'default';
			document.removeEventListener('click', hideTooltipWindow);
		}
	}

	diagramOsElement.addEventListener('mousemove', onMouseMoveOs);
	diagramHhElement.addEventListener('mousemove', onMouseMoveHh);
	diagramOsElement.addEventListener('click', e => {
		e.stopPropagation();
	});
	diagramHhElement.addEventListener('click', e => {
		e.stopPropagation();
	});
}
