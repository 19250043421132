import {Fragment, memo, useCallback, useEffect, useRef, useState} from 'react';
import throttle from 'lodash/throttle';
import noop from 'lodash/noop';

import {blockMods} from '../helpers/bem';
import breakpoint from '../helpers/breakpoint';
import {hub as loadPageHub, PAGE_LOADING_BEFORE_EVENT_NAME} from '../modules/ajax-load-page';

const NavItem = memo(function NavItem({
	itemIndex,
	item,
	view,
	isActive,
	setActiveItem,
	deactivate,
}) {
	const [deactivateOnMiscClick, setDeactivateOnMiscClick] = useState(false);

	const activate = useCallback(
		() => setActiveItem(itemIndex),
		[setActiveItem, itemIndex]
	);

	useEffect(
		() => {
			if (!isActive && deactivateOnMiscClick) {
				setDeactivateOnMiscClick(false);
			}
		},
		[isActive, deactivateOnMiscClick]
	);

	useEffect(
		() => {
			if (!deactivateOnMiscClick) {
				return undefined;
			}

			function onClick(e) {
				e.preventDefault();
				e.stopPropagation();
				deactivate();
			}

			function onTouchMove() {
				deactivate();
			}

			const onClickOptions = {passive: false};
			window.addEventListener('touchmove', onTouchMove);
			window.addEventListener('click', onClick, onClickOptions);
			return () => {
				window.removeEventListener('touchmove', onTouchMove);
				window.removeEventListener('click', onClick, onClickOptions);
			};
		},
		[deactivateOnMiscClick, deactivate]
	);

	const onKeyDown = useCallback(
		(e) => {
			if (view !== 'desktop' || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
				return;
			}

			if (e.key === ' ') {
				e.preventDefault();
				e.stopPropagation();

				if (isActive) {
					deactivate();
				} else {
					activate();
				}
			}
		},
		[view, isActive, activate, deactivate]
	);

	const linkRef = useRef();

	// we need to bind to `onTouchStart` "by hand" to override the `passive` default
	useEffect(
		() => {
			if (!linkRef.current || isActive || view !== 'desktop') {
				return undefined;
			}

			function onTouchStart(e) {
				e.preventDefault();
				e.stopPropagation();
				activate();
				setDeactivateOnMiscClick(true);
			}

			/** @var {Element} linkElem **/
			const linkElem = linkRef.current;
			linkElem.addEventListener('touchstart', onTouchStart, {passive: false});
			return () => {
				linkElem.removeEventListener('touchstart', onTouchStart);
			};
		},
		[activate, isActive, view]
	);

	const isVisible = view === 'mobile' || isActive;

	return (
		<li
			className={blockMods('vmzhb-main-navigation__item', {
				current: item.isCurrent,
				active: isActive,
			})}
			onMouseEnter={activate}
			onMouseLeave={deactivate}
		>
			{(() => {
				if (item.href) {
					return (
						<a
							href={item.href}
							onKeyDown={onKeyDown}
							ref={linkRef}
						>
							{item.title}
						</a>
					);
				} else {
					return item.title;
				}
			})()}

			{Array.isArray(item.children) && item.children.length > 0 && (
				<div
					className="vmzhb-main-navigation__sub"
					aria-hidden={isVisible ? 'false' : 'true'}
				>
					<ul>
						{item.children.map((subItem, index) => (
							<li
								key={index}
								className={blockMods(
									'vmzhb-main-navigation__sub-item',
									{current: subItem.isCurrent}
								)}
							>
								{(() => {
									if (subItem.href) {
										return (
											<a
												tabIndex={isVisible ? '0' : '-1'}
												href={subItem.href}
											>
												{subItem.title}
											</a>
										);
									} else {
										return <span>{subItem.title}</span>;
									}
								})()}
							</li>
						))}
					</ul>
				</div>
			)}
		</li>
	);
});

export default memo(function Nav({struct}) {
	const [activeItem, setActiveItem] = useState(undefined);
	const resetActive = useCallback(
		() => {
			setActiveItem(null);
		},
		[]
	);

	useEffect(
		() => {
			function onPageLoadingBeforeEventName() {
				resetActive();
			}

			loadPageHub.on(PAGE_LOADING_BEFORE_EVENT_NAME, onPageLoadingBeforeEventName);
			return () => {
				loadPageHub.off(PAGE_LOADING_BEFORE_EVENT_NAME, onPageLoadingBeforeEventName);
			};
		},
		[resetActive]
	);

	const [view, setView] = useState(() => (breakpoint('mobile') ? 'mobile' : 'desktop'));
	useEffect(
		() => {
			const onResize = throttle(
				() => {
					setView(breakpoint('mobile') ? 'mobile' : 'desktop');
				},
				200
			);
			const onResizeOptions = {passive: true};
			window.addEventListener('resize', onResize, onResizeOptions);
			return () => {
				window.removeEventListener('resize', onResize, onResizeOptions);
			};
		},
		[]
	);

	return (
		<Fragment>
			{struct.map((item, index) => (
				<NavItem
					key={index}
					itemIndex={index}
					item={item}
					view={view}
					isActive={activeItem === index}
					setActiveItem={setActiveItem}
					deactivate={index === activeItem ? resetActive : noop}
				/>
			))}
		</Fragment>
	);
});
