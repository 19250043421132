import ReactDom from 'react-dom';
import parseXml from '@neonaut/simplejs/dom/parse-xml';
import fetchInit from 'fetch-ponyfill';
import {createStore} from 'redux';
import {observeState} from '@neonaut/lib-redux/observe-state';
import throttle from 'lodash/throttle';
import {Provider as StoreProvider} from 'react-redux';

import breakpoint from '../../helpers/breakpoint';

import {hashFeeds, sortFeeds} from './feed-helpers';
import NotificationsHeader from './components/notifications-header';
import NotificationsBody from './components/notifications-body';
import NotificationsPlaceholder from './components/notifications-placeholder';
import * as storeMod from './store';

const {fetch} = fetchInit({Promise: Promise});

const ITEM_FIELDS = ['title', 'description', 'link', 'pubDate', 'guid'];
const DEFAULT_OPTIONS = {
	feeds: [],
	feedTicks: 60 * 1000,
};

function fetchText(request) {
//	if (typeof request === 'string') {
//		// TODO: Fix this magic!
//		request = request
//			.trim()
//			.replace(/^\//, isBrowser ? 'http://' + window.location.hostname + '/' : 'http://vmz.bremen.de/') // TODO!!!
//			.replace(/^http:/, isBrowser ? window.location.protocol : 'http:');
//	}

	return fetch(request)
		.then(res => {
			if (res.ok) {
				return res.text();
			} else {
				return Promise.reject(
					`Error fetching notifications "${request}": "${res.statusText}"`
				);
			}
		});
}

function parseItem(itemElement) {
	const item = {};

	ITEM_FIELDS.forEach(itemNodeName => {
		const node = itemElement.querySelector(itemNodeName);
		if (node) {
			item[itemNodeName] = node.textContent;
		}
	});

	if (item.pubDate) {
		item.date = Date.parse(item.pubDate);
	}

	return item;
}


function fetchFeed(feed) {
	return fetchText(typeof feed === 'string' ? feed : feed.url)
		.then((res) => {
			const rssDom = parseXml(res);
			const channelElement = rssDom.querySelector('rss > channel');
			return {
				...parseItem(channelElement),
				items: [...channelElement.querySelectorAll('item')].map(parseItem),
			};
		})
		.catch((err) => {
			console.error(err);
			return {items: []};
		});
}

function loadAndProcessData(options = {}, updateFeeds) {
	const feedFetches = options.feeds.map(fetchFeed);

	Promise.all(feedFetches)
		.then((feeds) => {
			updateFeeds(hashFeeds(sortFeeds(feeds)));
		})
		.catch((err) => {
			console.error(err);
		})
		.finally(() => {
			setTimeout(
				() => loadAndProcessData(options, updateFeeds),
				options.feedTicks
			);
		});
}

const sitePageElem = document.querySelector('.vmzhb-page');

const pageElem = window.document.querySelector('.vmzhb-notifications--page');
const headerElem = window.document.querySelector('.vmzhb-notifications--header');
const placeholderElem = window.document.querySelector('.vmzhb-notifications__placeholder');

const options = JSON.parse(pageElem.getAttribute('data-options'));
const store = createStore(
	storeMod.reducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({
		name: 'notifications',
	})
);
loadAndProcessData(
	{...DEFAULT_OPTIONS, ...options},
	(feeds) => store.dispatch(storeMod.setFeeds(feeds))
);
observeState(
	store,
	storeMod.selectPlaceholderHeight,
	(height) => sitePageElem.style.setProperty('--notifications-height', `${height}px`)
);

function onResize() {
	store.dispatch(storeMod.setView(breakpoint('mobile') ? 'mobile' : 'desktop'));
}

onResize();
window.addEventListener('resize', throttle(onResize, 200));
ReactDom.render(
	<StoreProvider store={store}><NotificationsHeader /></StoreProvider>,
	headerElem
);
ReactDom.render(
	<StoreProvider store={store}><NotificationsBody /></StoreProvider>,
	pageElem
);
ReactDom.render(
	<StoreProvider store={store}><NotificationsPlaceholder /></StoreProvider>,
	placeholderElem
);
