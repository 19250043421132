import {render as reactRender} from 'react-dom';

import Nav from '../components/Nav';

function parseNavStruct(navElem) {
	return [...navElem.querySelectorAll(':scope > li')]
		.map((item) => {
			const link = item.querySelector(':scope > a');

			const children = [...item.querySelectorAll('ul > li')]
				.map((subItem) => {
					const subLink = subItem.querySelector('a, em');
					if (!subLink) {
						return undefined;
					}
					const title = subLink.textContent && subLink.textContent.trim();
					const isCurrent =
						subItem.classList.contains('vmzhb-main-navigation__sub-item--current');

					switch (subLink.tagName) {
						case 'A':
							return {
								title: title,
								href: subLink.href,
								isCurrent: isCurrent,
							};

						case 'EM':
							return {
								title: title,
								href: null,
								isCurrent: isCurrent,
							};

						default:
							throw new Error('unreachable');
					}
				})
				.filter((subItem) => subItem !== undefined);

			return {
				title: link && link.textContent && link.textContent.trim(),
				href: link && link.href,
				isCurrent: item.classList.contains('vmzhb-main-navigation__item--current'),
				children: children,
			};
		});
}

const navElem = document.querySelector('#vmzhb-main-navigation > ul');
const navStruct = parseNavStruct(navElem);

const newNavElem = document.createElement('ul');
navElem.parentNode.replaceChild(newNavElem, navElem);
reactRender(<Nav struct={navStruct} />, newNavElem);

// const originalMainNavElem = document.getElementById('vmzhb-main-navigation');

export function updateMainNav(mainNavElem) {
	const nextNavStruct = parseNavStruct(mainNavElem.querySelector(':scope > ul'));
	reactRender(<Nav struct={nextNavStruct} />, newNavElem);

	// replacing nav buttons kills the listener on `toggle-content` button, so don't do that...
	// TODO: make it work... ?!
	// while (originalMainNavElem.firstChild) {
	// 	originalMainNavElem.removeChild(originalMainNavElem.firstChild);
	// }

	// [...mainNavElem.children].forEach((elem) => {
	// 	if (elem.tagName === 'UL') {
	// 		const navStruct = parseNavStruct(elem);
	// 		originalMainNavElem.appendChild(newNavElem);
	// 		reactRender(<Nav struct={navStruct} />, newNavElem);
	// 	} else {
	// 		originalMainNavElem.appendChild(elem.cloneNode(true));
	// 	}
	// });
}
