export default {
	Bremerhaven: {
		label: 'Bremerhaven',
		bounds: [932761, 7077190, 967234, 7095764],
	},
	Bremen: {
		label: 'Bremen',
		bounds: [944557, 6976584, 1017784, 7016254],
	},
	BremenNord: {
		label: 'Bremen Nord',
		bounds: [954622, 7009925, 971973, 7019384],
	},
	BremenCity: {
		label: 'Bremen City',
		bounds: [977772, 6996056, 982348, 6998536],
	},
};
