function addPolyfill(e) {
	const type = e.type === 'focus' ? 'focusin' : 'focusout';
	const event = new CustomEvent(type, {bubbles: true, cancelable: false});
	event.c1Generated = true;
	e.target.dispatchEvent(event);
}

function removePolyfill(e) {
	if (!e.c1Generated) {
		// focus after focusin, so chrome will the first time trigger two times focusin
		document.removeEventListener('focus', addPolyfill, true);
		document.removeEventListener('blur', addPolyfill, true);
		document.removeEventListener('focusin', removePolyfill, true);
		document.removeEventListener('focusout', removePolyfill, true);
	}
	setTimeout(() => {
		document.removeEventListener('focusin', removePolyfill, true);
		document.removeEventListener('focusout', removePolyfill, true);
	});
}

if (!window.onfocusin) {
	document.addEventListener('focus', addPolyfill, true);
	document.addEventListener('blur', addPolyfill, true);
	document.addEventListener('focusin', removePolyfill, true);
	document.addEventListener('focusout', removePolyfill, true);
}
