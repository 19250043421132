// TODO: use breakpoints module instead of checking viewport again

import throttle from 'lodash/throttle';
import {trackEvent} from '@neonaut/simplejs/piwik';
import toggleClass from '@neonaut/simplejs/dom/toggle-class';

import breakpoint from '../helpers/breakpoint';

import './a11y/polyfill-focus-in-out';

const CONTAINER_ID = 'vmzhb-main-navigation';
const CONTAINER_CLASS_PREFIX = 'vmzhb-main-navigation';
const BUTTON_TARGET_SELECTOR = '.js-add-main-navigation-toggle-button';

let currentState = '';

const doc = window.document;
const docElement = doc.documentElement;
const container = doc.getElementById(CONTAINER_ID);
const toggleNavigationButton = doc.createElement('button');
toggleNavigationButton.setAttribute('class', '[ vmzhb-main-navigation-toggle ][ js-vmzhb-toggle-main-navigation ]');
toggleNavigationButton.setAttribute('type', 'button');
toggleNavigationButton.setAttribute('aria-haspopup', 'true');
toggleNavigationButton.setAttribute('aria-controls', CONTAINER_ID);
toggleNavigationButton.setAttribute('aria-label', 'Hauptmenü');
toggleNavigationButton.innerHTML = '<i></i>';

let listeners = [];
let scrollPosBeforeOpen = 0;

function toggleNavigation() {
	const wasOpen = getIsNavigationOpen();

	if (!wasOpen) {
		// save scroll pos
		scrollPosBeforeOpen = window.pageYOffset || document.scrollTop || 0;
	}

	toggleNavigationButton.setAttribute('aria-expanded', wasOpen ? 'false' : 'true');
	toggleClass(container, CONTAINER_CLASS_PREFIX + '--inactive', wasOpen);
	toggleClass(container, CONTAINER_CLASS_PREFIX + '--active', !wasOpen);
	toggleClass(docElement, 'vmzhb--main-navigation-open', !wasOpen);
	trackEvent('Navigation', 'Toggle');

	listeners.forEach(l => l(!wasOpen));

	if (wasOpen) {
		// restore scroll pos
		window.requestAnimationFrame(() => {
			document.documentElement.scrollTop = document.body.scrollTop = scrollPosBeforeOpen;
		});
	}
}

toggleNavigationButton.addEventListener('click', toggleNavigation);

export function closeNavigation() {
	const isOpen = toggleNavigationButton.getAttribute('aria-expanded') === 'true';
	if (isOpen) {
		toggleNavigation();
	}
}

export function getIsNavigationOpen() {
	return toggleNavigationButton.getAttribute('aria-expanded') === 'true';
}

export function unsubscribeNavigationOpenChange(listener) {
	listeners = listeners.filter(l => l !== listener);
}

export function subscribeNavigationOpenChange(listener) {
	listeners.push(listener);
	return () => unsubscribeNavigationOpenChange(listener);
}

function check() {
	if (!container) {
		return;
	}

	const newState = breakpoint('mobile') ? 'mobile' : 'desktop';
	if (newState === currentState) {
		return;
	}

	currentState = newState;

	// detach button
	if (toggleNavigationButton.parentNode) {
		toggleNavigationButton.parentNode.removeChild(toggleNavigationButton);
	}
	toggleNavigationButton.setAttribute('aria-expanded', 'false');

	if (newState === 'mobile') {
		container.classList.add(CONTAINER_CLASS_PREFIX + '--inactive');
		container.classList.remove(CONTAINER_CLASS_PREFIX + '--active');
		document.querySelector(BUTTON_TARGET_SELECTOR).appendChild(toggleNavigationButton);
	} else {
		container.classList.remove(CONTAINER_CLASS_PREFIX + '--inactive');
		container.classList.add(CONTAINER_CLASS_PREFIX + '--active');
	}
}

const throttledCheck = throttle(check, 100, {leading: false, trailing: true});

window.addEventListener('resize', throttledCheck);
throttledCheck();
