import scrolled from './scrolled';

if (typeof window !== 'undefined') {
	window.scrollMe = scrollMe;
}

function scrollMe(elem, list, extra, force) {
	if (
		typeof window !== 'undefined' && elem &&
		(list || scrolled())
	) {
		const isFullscreen = !!document.querySelector('.ms3-wrapper--fullscreen');
		const scrollTarget = list && isFullscreen && document.querySelector('.js-sticky-parent') ?
			document.querySelector('.js-sticky-parent') : window;
		const scrollY = (scrollTarget === window ? scrollTarget.scrollY : scrollTarget?.scrollTop);
		const elementPosition = list && isFullscreen ? elem.offsetTop : elem.getBoundingClientRect().top;

		if (list && isFullscreen) {
			if (force || elementPosition < scrollY) {
				scrollTarget.scrollTo({
					top: elementPosition,
					// behavior: 'smooth',
				});
			}
		} else {
			const notificationsElement = document.querySelector('.vmzhb-notifications .vmzhb-area');
			// headerOffset 50 (header) + (notification) + 4 (Breite outline) + 4 (für die Schönheit) = 58 + notification
			const headerOffset = 58 +
				(notificationsElement?.offsetHeight || 0) +
				(document.querySelector('.ms3-wrapper--mapOnly') || document.querySelector('.ms3-wrapper--mobile') ? 45 : 0) +
				(extra || 0)
			;

			const offsetPosition = elementPosition + scrollY - headerOffset;
			// console.log('scrollMe', {
			// 	elementPosition, offsetPosition, y: window.pageYOffset, scrollY, headerOffset, extra, e: (extra||0), list, force,
			// 	eLTscreenHalf:elementPosition < window.screen.height/2,
			// 	oLTscrollY:  offsetPosition < scrollY,
			// 	willScroll: (force || (elementPosition < window.screen.height / 2 && offsetPosition < scrollY)),
			// });
			if (force ||
				(elementPosition < window.screen.height / 2 && offsetPosition < scrollY
					&& elementPosition !== 0 // wir haben zwei Listen weil Bremen die nur via CSS ausblendet statt sie per React abzuschalten. bei der unsichtbaren ist elementPosition===0
				)
			) {
				scrollTarget.scrollTo({
					top: offsetPosition,
					// behavior: 'smooth',
				});
			}
		}
	}
}

export default scrollMe;
