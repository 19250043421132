import {memo} from 'react';
import {useSelector} from 'react-redux';

import * as storeMod from '../store';

import Notifications from './notifications';

function NotificationsHeader() {
	const view = useSelector(storeMod.selectView);

	switch (view) {
		case 'mobile':
			return null;

		case 'desktop':
			return (
				<div className="vmzhb-area vmzhb-area--notifications">
					<div className="vmzhb-container">
						<div className="vmzhb-container__inner">
							<Notifications />
						</div>
					</div>
				</div>
			);

		default:
			throw new Error('Unsupported view');
	}
}

export default memo(NotificationsHeader);
