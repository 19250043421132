import {BREAKPOINTS} from '../_variables';

export default function breakpoint(bpInput) {
	let bp;
	if (typeof bpInput === 'string') {
		if (!BREAKPOINTS[bpInput]) {
			throw new Error(`Unknown breakpoint \`${bpInput}\``);
		}
		bp = BREAKPOINTS[bpInput];
	} else {
		bp = bpInput;
	}

	const vw =
		document.documentElement.clientWidth ||
		document.body.clientWidth ||
		window.innerWidth;

	if (
		Array.isArray(bp) && bp.length === 2 &&
		typeof bp[0] === 'number' && typeof bp[1] === 'number'
	) {
		return bp[0] <= vw && vw <= bp[1];
	}

	if (typeof bp === 'number') {
		return bp <= vw;
	}

	throw new TypeError(`Invalid breakpoint definition \`${bp}\` for input \`${bpInput}\``);
}
