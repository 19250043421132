import { titleSelector } from "@mapsight/ui/store/selectors";
import { useSelector } from "react-redux";

const TitleBar = () => {
	const title = useSelector(titleSelector);

	return (
		<div className="ms3-flex ms3-flex--row ms3-flex-no-shrink">
			<div className="ms3-page-title ms3-flex-grow">
				<h1>{title || ""}</h1>
			</div>
		</div>
	);
};

export default TitleBar;
