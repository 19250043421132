import {German as flatpickrDe} from 'flatpickr/dist/l10n/de';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

import range from 'lodash/range';
import {useCallback, useEffect, useMemo, useState} from 'react';

import Flatpickr from 'react-flatpickr';
import config from '../config';

import {dateToYmd, getApiUrl, queryJson} from '../helpers';

import Chart from './Chart';

const ChartWrapper = function ChartWrapper({
	showStationsList,
	data: initialData,
	stations,
	apiAction: defaultApiAction,
	resolution,
	limit,
	type,
}) {
	const [startDateYmd, setStartDateYmd] = useState(null);
	const [flatpickrValue, setFlatpickrValue] = useState(null);
	const [apiAction, setApiAction] = useState(null);
	const usedApiAction = apiAction || defaultApiAction;

	const stationIds = useMemo(function () {
		return stations.map(({id}) => id).join(',');
	}, [stations]);

	const handleYearSelect = useCallback(function (e) {
		const year = parseInt(e.target.value, 10);
		if (year) {
			setStartDateYmd(year + '-01-01');
		}
	}, []);

	const [data, setData] = useState(null);

	const fetchData = useCallback(function () {
		setApiAction('Values');
	}, [setApiAction]);

	useEffect(function () {
		if (apiAction && startDateYmd) {
			queryJson(
				{
					action: apiAction,
					apiFormat: 'json',
					resolution: resolution,
					limit: limit,
					startDate: startDateYmd,
					stationId: stationIds,
				},
				response => {
					setData(response);
				},
			);
		}
	}, [apiAction, startDateYmd, resolution, limit, stationIds]);

	const submit = useCallback(function (e) {
		e.preventDefault();
		fetchData();
	}, [fetchData]);

	const handleDateChange = useCallback(function (selectedDates) {
		setFlatpickrValue(selectedDates);
		setStartDateYmd(dateToYmd(selectedDates[0]));
		fetchData();
	}, [setFlatpickrValue, setStartDateYmd, fetchData]);

	const handleDateBlur = useCallback(function () {
		fetchData();
	}, [fetchData]);

	const reset = useCallback(function (e) {
		e.preventDefault();
		setApiAction(null);
		setStartDateYmd(null);
		setData(null);
		setFlatpickrValue(null);
	}, [setApiAction, setStartDateYmd, setData, setFlatpickrValue]);

	const downloadHref = useMemo(function () {
		return getApiUrl({
			action: usedApiAction,
			apiFormat: 'csv',
			resolution: resolution,
			startDate: startDateYmd,
			limit: limit,
			stationId: stationIds,
		});
	}, [usedApiAction, startDateYmd, resolution, limit, stationIds]);

	const flatpickrOptions = useMemo(function () {
		return {
			disableMobile: true,
			dateFormat: 'd.m.Y',
			locale: flatpickrDe,
			minDate: new Date(config.EARLIEST_YEAR + '-01-01T00:00:00'),
			maxDate: resolution === 'daily' ? new Date().fp_incr(-(limit - 1)) : null,
			plugins: resolution === 'monthly' ? [new MonthSelectPlugin({
				shorthand: true,
				dateFormat: 'm.Y',
			})] : [],
		};
	}, [resolution, limit]);

	let showDateSelect = true;
	let years = null;
	if (resolution === 'yearly') {
		years = range(config.EARLIEST_YEAR, 1 + Math.max(config.EARLIEST_YEAR, (new Date()).getFullYear() - limit + 1));
		showDateSelect = years.length > 1;
	}

	return (
		<div className="vmzhb-chart-wrapper">
			<div className="vmzhb-chart-buttons">
				{showDateSelect ? (
					<form className="vmzhb-chart-form" onSubmit={submit}>
						{years ? (
							<select
								className="vmzhb-chart-date-selector"
								onBlur={handleYearSelect}
							>
								<option key="empty" value="">Zeitraum auswählen …</option>
								{years.map(year => (
									<option key={year} value={year}>{year} bis {year + limit - 1}</option>
								))}
							</select>
						) : (
							<Flatpickr
								className="vmzhb-chart-date-selector"
								placeholder={'Zeitraum auswählen …'}
								options={flatpickrOptions}
								onChange={handleDateChange}
								onBlur={handleDateBlur}
								value={flatpickrValue}
							/>
						)}

						{/* <button className="vmzhb-chart-button vmzhb-chart-button--submit" type="submit" onClick={submit}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 452" width="12" height="12">
								<path d="M345.4 248.3L151.2 442.6a31.6 31.6 0 11-44.8-44.8l172-171.9L106.3 54a31.6 31.6 0 0144.8-44.7l194.2 194.3a31.5 31.5 0 010 44.7z" />
							</svg>

							<span className="visuallyhidden">Auswahl ändern</span>
						</button>*/}

						{startDateYmd ? (
							<button className="vmzhb-chart-button vmzhb-chart-button--reset" type="reset"
								onClick={reset}
								title="Auswahl zurücksetzen">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461 461" width="12" height="12">
									<path fill="#444"
										d="M285 230L456 59c6-6 6-16 0-22L424 5a16 16 0 00-22 0L230 176 59 5a16 16 0 00-22 0L5 37c-7 6-7 16 0 22l171 171L5 402c-6 6-6 15 0 21l32 33a16 16 0 0022 0l171-171 172 171a16 16 0 0021 0l33-33c6-6 6-15 0-21L285 230z" />
								</svg>
								<span className="visuallyhidden">Auswahl zurücksetzen</span>
							</button>
						) : null}
					</form>
				) : (
					<span />
				)}

				<div>
					<a className="vmzhb-chart-button vmzhb-chart-button--download"
						href={downloadHref} rel="download noreferrer" target="_blank" title="Daten als CSV-Datei herunterladen …">
						CSV herunterladen …
					</a>
				</div>
			</div>

			<Chart
				type={type}
				stations={stations}
				showStationList={showStationsList}
				data={data || initialData}
				truncateXAxis={!data}
				resolution={resolution}
			/>
		</div>
	);
};

export default ChartWrapper;
