function focusPageSection(target) {
	target.setAttribute('tabindex', '-1');
	target.addEventListener('focusout', removeTargetTabIndex);
	target.addEventListener('blur', removeTargetTabIndex);
	target.focus();
}

function removeTargetTabIndex(e) {
	e.target.removeAttribute('tabindex');
}

/**
 * This function looks for a change in the hash (activation of an in-page link) and sets focus to and
 * highlights the target element. This is necessary because webkit does not set focus as it should. If
 * the hash is empty (the user hit the back button after activating an in-page link) focus is set to body.
 */
function focusLocationTarget() {
	const hash = document.location.hash;
	if (!hash) {
		return;
	}

	try {
		const locationTarget = document.querySelector(document.location.hash); // this may throw if the hash is not a valid id selector
		if (locationTarget) {
			focusPageSection(locationTarget);
		}
	} catch (e) {
		// empty
	}
}

window.addEventListener('hashchange', focusLocationTarget);
focusLocationTarget();
