import {hash} from '../../helpers/hash';

export function flattenFeeds(feeds) {
	return feeds.reduce((prevItems, curFeed) => prevItems.concat(curFeed.items), []);
}

export function sortFeeds(feeds) {
	feeds = feeds.map(feed => {
		feed.items = _sortFeedElements(feed.items);
		return feed;
	});

	return _sortFeedElements(feeds);
}

function _sortFeedElements(elems) {
	return elems.sort((a, b) => {
		if (a.date && b.date) {
			return b.date - a.date;
		}

		return a.hash - b.hash;
	});
}

export function hashFeeds(feeds) {
	return feeds.map(feed => {
		feed.hash = hash(feed);

		feed.items = feed.items.map(item => {
			item.hash = hash(item);
			return item;
		});
		return feed;
	});
}

