import ReactDOM from 'react-dom';

import getQueryStringParameter from '@neonaut/simplejs/get-query-string-parameter';

import loadJson from '../../helpers/xhr-json';

import config from './config';
import {mapFeaturesToStations, queryJson} from './helpers';
import ChartWrapper from './components/ChartWrapper';

const charts = [
	{
		selector: '#radzaehler-vergleich-001',
		action: 'LastValues',
		resolution: 'daily',
		limit: 7,
		type: 'column',
	},
	{
		selector: '#radzaehler-vergleich-002',
		action: 'LastValues',
		resolution: 'daily',
		limit: 30,
		type: 'area',
	},
	{
		selector: '#radzaehler-vergleich-003',
		action: 'LastValues',
		resolution: 'monthly',
		limit: 25,
		type: 'area',
	},
	{
		selector: '#radzaehler-vergleich-004',
		action: 'LastValues',
		resolution: 'yearly',
		limit: 10,
		type: 'area',
	},
];

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function render() {
	let stations = [];
	let showStationsList = getQueryStringParameter(window.location.search, 'stationId');
	showStationsList = showStationsList ? showStationsList.split(',') : false;

	function renderChart(chartConfig) {
		ReactDOM.render(
			<ChartWrapper
				type={chartConfig.type || 'column'}
				showStationsList={showStationsList}
				stations={stations}
				data={chartConfig.data}
				resolution={chartConfig.resolution}
				limit={chartConfig.limit}
				apiAction={chartConfig.action}
			/>,
			chartConfig.containerElement
		);
	}

	const visibleCharts = [];
	charts.forEach(chartConfig => {
		const containerElement = document.querySelector(chartConfig.selector);
		if (!containerElement) {
			return;
		}

		chartConfig.containerElement = containerElement;
		renderChart(chartConfig);

		visibleCharts.push(chartConfig);
	});

	if (!visibleCharts.length) {
		return;
	}

	loadJson(config.LIST_URL, ({features}) => {
		stations = mapFeaturesToStations(features);

		const stationIds = stations.map(({id}) => id).join(',');
		visibleCharts.forEach(async (chartConfig, chartIndex) => {
			if (chartIndex > 0) {
				await sleep(500 * chartIndex);
			}

			queryJson(
				{
					action: 'LastValues',
					resolution: chartConfig.resolution,
					limit: chartConfig.limit,
					stationId: stationIds,
				},
				response => {
					chartConfig.data = response;
					renderChart(chartConfig);
				}
			);
		});
	});
}
