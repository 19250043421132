import {FEATURE_SELECTIONS, FEATURE_SOURCES} from '@mapsight/ui/config/constants/controllers';
import {FEATURE_SELECTION_HIGHLIGHT, FEATURE_SELECTION_SELECT} from '@mapsight/ui/config/feature/selections';

// TODO update feature-definitions to use shortcuts now provided by @mapsight
//import {features, interactiveFeatures, metaData, metaDataWithLegend, userGeolocation} from '@mapsight/ui/config/map/layers';

const defaultLayerGroup = 'Ebenen';

const defaultOptionsFeatureLayer = {
	visible: false,
	style: 'features',
	//updateWhileAnimating: true,
	//updateWhileInteracting: true,
	renderBuffer: 200,
};

const defaultOptionsInteractiveFeatureLayer = {
	...defaultOptionsFeatureLayer,
	selectionStates: [
		FEATURE_SELECTION_SELECT,
		FEATURE_SELECTION_HIGHLIGHT,
	],
	selections: {
		//'second-touch': FEATURE_SELECTION_SELECT,
		//touch: FEATURE_SELECTION_HIGHLIGHT,
		mousedown: FEATURE_SELECTION_SELECT,
		touch: FEATURE_SELECTION_SELECT,
		mouseover: FEATURE_SELECTION_HIGHLIGHT,
	},
};

const featureSource = (id, clusterFeatures = false) => ({
	type: 'VectorFeatureSource',
	options: {
		featureSourceId: id,
		featureSourcesControllerName: FEATURE_SOURCES,
		featureSelectionsControllerName: FEATURE_SELECTIONS,
		clusterFeatures: clusterFeatures,
	},
});

export const osm = {
	type: 'TileLayer',
	metaData: {
		title: 'OpenStreetMap',
		attribution: 'Karte: &copy; <a onfocus="window.scrollMe(event.target)" href="http://www.openstreetmap.org/copyright" rel="external">OpenStreetMap-Mitwirkende </a>',
		visibleInLayerSwitcher: false,
		isBaseLayer: true,
		group: defaultLayerGroup,
		legend: true,
		miniLegend: true,
	},
	options: {
		source: {
			type: 'OsmSource',
			options: {
				url: '/osm-tiles/{z}/{x}/{y}.png?updated=2023-04-12',
			},
		},
		visible: true,
	},
};
export default {
	osm: osm,
	geoInformationBremenBaseMap: {
		type: 'TileLayer',
		metaData: {
			title: 'Stadtplan',
			attribution: '© GeoBasis-DE / GeoInformation Bremen 2015',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
		},
		options: {
			source: {
				type: 'TileWMSSource',
				options: {
					projection: 'EPSG:900913', // equal to ESPG:3857 (openlayers default projection) but the server only known the name EPSG:900913
					url: 'https://gdi1.geo.bremen.de/mapproxy/Stadtplan/service',
					params: {
						LAYERS: 'Stadtplan',
						VERSION: '1.1.1',
					},
				},
			},
			visible: false,
		},
	},
	truckNet: {
		type: 'TileLayer',
		metaData: {
			title: 'LKW: Führungsnetz',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
		options: {
			source: {
				type: 'TileWMSSource',
				options: {
					projection: 'EPSG:900913', // equal to ESPG:3857 (openlayers default projection) but the server only known the name EPSG:900913
					url: 'https://gdi1.geo.bremen.de/mapproxy/LKW/service',
					params: {
						LAYERS: 'LKW_Gewerbegebiete,LKW_Farbiges_Netz,LKW_Nachtfahrverbote,LKW_Autobahnbezeichnungen',
						VERSION: '1.1.1',
					},
				},
			},
			visible: false,
			opacity: 0.6,
		},
	},
	truckUmweltzone: {
		type: 'TileLayer',
		metaData: {
			title: 'LKW: Umweltzone',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
		options: {
			source: {
				type: 'TileWMSSource',
				options: {
					projection: 'EPSG:900913', // equal to ESPG:3857 (openlayers default projection) but the server only known the name EPSG:900913
					url: 'https://gdi1.geo.bremen.de/mapproxy/LKW/service',
					params: {
						LAYERS: 'LKW_Umweltzone',
						VERSION: '1.1.1',
					},
				},
			},
			visible: false,
			opacity: 0.6,
		},
	},
	poisTruckInformation: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisTruckInformation'),
		},
		metaData: {
			title: 'LKW: StVO-Beschränkungen',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	poisTruckParking: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisTruckParking'),
		},
		metaData: {
			title: 'LKW: Rastplätze',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
	traffic: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			//renderMode: 'image',
			source: featureSource('traffic'),
			style: 'traffic',
		},
		metaData: {
			title: 'Verkehrslage',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	trafficMessagesBremenUmzu: {
		type: 'VectorLayer',
		group: 'interactive',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('trafficMessagesBremenUmzu'),
		},
		metaData: {
			title: 'Verkehrswarndienst',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	trafficMessagesBremenUmzuPreview: {
		type: 'VectorLayer',
		group: 'interactive',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('trafficMessagesBremenUmzuPreview'),
		},
		metaData: {
			title: 'Verkehrswarndienst (inkl. Vorschau)',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	trafficMessagesAll: {
		type: 'VectorLayer',
		group: 'interactive',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('trafficMessagesAll'),
			//renderMode: 'image',
		},
		metaData: {
			title: 'Verkehrswarndienst (bundesweit)',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	constructionWork: {
		type: 'VectorLayer',
		group: 'interactive',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('constructionWork'),
		},
		metaData: {
			title: 'Baustellen',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	constructionWorkPreview: {
		type: 'VectorLayer',
		group: 'interactive',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('constructionWorkPreview'),
		},
		metaData: {
			title: 'Baustellen (inkl. Vorschau)',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	constructionWorkArchive: {
		type: 'VectorLayer',
		group: 'interactive',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('constructionWorkArchive'),
		},
		metaData: {
			title: 'Baustellen Archiv',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	trafficLightFailures: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('trafficLightFailures'),
		},
		metaData: {
			title: 'Störungen',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	parking: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('parking'),
		},
		metaData: {
			title: 'Parkhäuser und -plätze',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	poisWebcams: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisWebcams'),
		},
		metaData: {
			title: 'Webcams',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
		},
	},
	poisBicycleCounters: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisBicycleCounters'),
		},
		metaData: {
			title: 'Fahrradzähler',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
		},
	},
	poisBusinessParks: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisBusinessParks'),
		},
		metaData: {
			title: 'Gewerbegebiete',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
		},
	},
	poisVerticalClearance: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisVerticalClearance'),
		},
		metaData: {
			title: 'Durchfahrtshöhen',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
		},
	},
	constructionWorkDetours: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('constructionWorkDetours'),
		},
		metaData: {
			title: 'Umleitungen',
			visibleInLayerSwitcher: true,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	parkAndRide: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('parkAndRide'),
		},
		metaData: {
			title: 'Park & Ride',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
			legend: true,
			miniLegend: true,
		},
	},
	poisHandicappedParking: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			style: {style: 'features', mapsightIconId: 'behindertenparken'},
			source: featureSource('poisHandicappedParking', true),
		},
		metaData: {
			title: 'Behindertenparken',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
	poisResidentParking: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisResidentParking'),
		},
		metaData: {
			title: 'Bewohnerparken',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
	poisBikeAndRide: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisBikeAndRide'),
		},
		metaData: {
			title: 'Bike & Ride',
			group: defaultLayerGroup,
		},
	},
	poisBicycleParking: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			style: {style: 'features', mapsightIconId: 'bundr'},
			source: featureSource('poisBicycleParking', true),
		},
		metaData: {
			title: 'Fahrradstellplätze',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
	poisTaxiParking: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisTaxiParking'),
		},
		metaData: {
			title: 'Taxistände',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
	poisBusParking: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			source: featureSource('poisBusParking'),
		},
		metaData: {
			title: 'Busparkplätze',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
	poisMessstellen: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			style: {style: 'features', mapsightIconId: 'stadtteil'},
			source: featureSource('poisMessstellen', true),
		},
		metaData: {
			title: 'Messstellen',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
	userGeolocation: {
		type: 'VectorLayer',
		options: {
			source: featureSource('userGeolocation'),
			visible: true,
			style: 'userGeolocation',
			updateWhileAnimating: true,
			updateWhileInteracting: true,
			renderBuffer: 400,
		},
		metaData: {
			title: 'Eigener Standort',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
	searchResult: {
		type: 'VectorLayer',
		options: {
			...defaultOptionsInteractiveFeatureLayer,
			// import defaultsDeep from 'lodash/defaultsDeep';
			//source: defaultsDeep({
			//		options: {
			//			fitFeaturesInViewOptions: {
			//				duration: 300,
			//				padding: [60, 100, 60, 100],
			//				keepZoom: false,
			//				maxZoom: 17,
			//				skipIfInView: false,
			//			},
			//			keepFeaturesInViewOptions: {
			//				duration: 300,
			//				padding: [60, 100, 60, 100],
			//				keepZoom: false,
			//				maxZoom: 17,
			//				skipIfInView: false,
			//			},
			//			//centerFeaturesInViewSelections: [FEATURE_SELECTION_SELECT]
			//		}
			//	},
			//	featureSource('searchResult')
			//),
			source: featureSource('searchResult'),
			visible: true,
		},
		metaData: {
			title: 'Suchergebnisse',
			visibleInLayerSwitcher: false,
			group: defaultLayerGroup,
		},
	},
};
