import {flattenFeeds} from './feed-helpers';

const EMPTY_ARRAY = [];

export function selectItems(state) {
	return state && state.items || EMPTY_ARRAY;
}

export function selectPlaceholderHeight(state) {
	return state && state.placeholderHeight || 0;
}

export function selectView(state) {
	return state && state.view;
}

export const ActionTypes = {
	SET_PLACEHOLDER_HEIGHT: 'SET_PLACEHOLDER_HEIGHT',
	SET_FEEDS: 'SET_FEEDS',
	SET_VIEW: 'SET_VIEW',
};

export function setPlaceholderHeight(height) {
	return {
		type: ActionTypes.SET_PLACEHOLDER_HEIGHT,
		height: height,
	};
}

export function setFeeds(feeds) {
	return {
		type: ActionTypes.SET_FEEDS,
		feeds: feeds,
	};
}

export function setView(view) {
	return {
		type: ActionTypes.SET_VIEW,
		view: view,
	};
}

export function reducer(state = {placeholderHeight: 0, items: EMPTY_ARRAY}, action) {
	switch (action.type) {
		case ActionTypes.SET_PLACEHOLDER_HEIGHT:
			return {
				...state,
				placeholderHeight: action.height,
			};

		case ActionTypes.SET_FEEDS:
			return {
				...state,
				items:
					flattenFeeds(action.feeds)
						.sort((a, b) => (a.date && b.date ? b.date - a.date : a.hash - b.hash)),
			};

		case ActionTypes.SET_VIEW:
			return {
				...state,
				view: action.view,
			};

		default:
			return state;
	}
}
