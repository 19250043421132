import updateQueryStringParameter from '@neonaut/simplejs/update-query-string-parameter';

const ATTRIBUTE_NAME = 'data-refresh-interval';
const SELECTOR = '[' + ATTRIBUTE_NAME + ']';
const CACHE_BUST_PARAMETER = 't';

export function render() {
	[...window.document.querySelectorAll(SELECTOR)].forEach(element => {
		const refreshInterval = parseInt(element.getAttribute(ATTRIBUTE_NAME), 10) * 1000;
		element.removeAttribute(ATTRIBUTE_NAME);

		function updateSrc() {
			if (!element || !element.parentNode) {
				return;
			}

			const src = element.getAttribute('src');
			const changedSrc = updateQueryStringParameter(src, CACHE_BUST_PARAMETER, Math.round(+new Date() / refreshInterval)); // FIXME update of updateQueryString now call encodeURI on
			element.setAttribute('src', changedSrc);
			window.setTimeout(updateSrc, refreshInterval);
		}

		window.setTimeout(updateSrc, refreshInterval);
	});
}
