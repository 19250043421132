export default {
	bremen: {
		title: 'Bremen',
		type: 'group',
		entries: {
			A270: {
				title: 'A270',
				x: 8.65726,
				y: 53.17258,
			},
			A281: {
				title: 'A281',
				x: 8.76021,
				y: 53.07515,
			},
			B6: {
				title: 'B6',
				x: 8.81399,
				y: 53.03984,
			},
			B74: {
				title: 'B74',
				x: 8.53331,
				y: 53.19911,
			},
			B75: {
				title: 'B75',
				x: 8.71489,
				y: 53.04926,
			},
			Innenstadt: {
				title: 'Innenstadt',
				x: 8.80312,
				y: 53.07582,
			},
			Neustadt: {
				title: 'Neustadt',
				x: 8.79233,
				y: 53.06932,
			},
			Huchting: {
				title: 'Huchting',
				x: 8.73825,
				y: 53.05194,
			},
			Ueberseestadt: {
				title: 'Überseestadt',
				x: 8.76681,
				y: 53.09485,
			},
			Hemelingen: {
				title: 'Hemelingen',
				x: 8.88027,
				y: 53.05557,
			},
			Osterholz: {
				title: 'Osterholz',
				x: 8.94667,
				y: 53.05551,
			},
			Oberneuland: {
				title: 'Oberneuland',
				x: 8.93286,
				y: 53.08986,
			},
			Horn_Lehe: {
				title: 'Horn/Lehe',
				x: 8.87784,
				y: 53.10362,
			},
		},
	},
	'In Nachbarlaendern': {
		title: 'In Nachbarländern',
		type: 'group',
		entries: {
			'Bremer Kreuz': {
				title: 'Bremer Kreuz',
				x: 8.98376,
				y: 53.04158,
			},
			Bremerhaven: {
				title: 'Bremerhaven',
				x: 8.58251,
				y: 53.55223,
			},
			Cloppenburg: {
				title: 'Cloppenburg',
				x: 8.03984,
				y: 52.84613,
			},
			Cuxhaven: {
				title: 'Cuxhaven',
				x: 8.69424,
				y: 53.86878,
			},
			Delmenhorst: {
				title: 'Delmenhorst',
				x: 8.62456,
				y: 53.0481,
			},
			Diepholz: {
				title: 'Diepholz',
				x: 8.36862,
				y: 52.61137,
			},
			Elsfleth: {
				title: 'Elsfleth',
				x: 8.45306,
				y: 53.2357,
			},
			Hamburg: {
				title: 'Hamburg',
				x: 9.99661,
				y: 53.55034,
			},
			Hannover: {
				title: 'Hannover',
				x: 9.73451,
				y: 52.37448,
			},
			Rotenburg: {
				title: 'Rotenburg (Wümme)',
				x: 9.40089,
				y: 53.11089,
			},
			Brinkum: {
				title: 'Brinkum (Stuhr)',
				x: 8.78783,
				y: 53.01168,
			},
			Verden: {
				title: 'Verden',
				x: 9.22411,
				y: 52.92234,
			},
		},
	},
};
