import MiniLegend from "./MiniLegend";

function MapOverlayEnd() {
	return (
		<div className="vmzhb-map-overlay-bottom">
			<div className="vmzhb-mini-legend">
				<MiniLegend/>
			</div>
		</div>
	);
}

export default MapOverlayEnd;
