import Highcharts from './highcharts';
import config from './config';
import {formatPrettyNumber, mapChartEntry, queryJson} from './helpers';

const CLASS_PREFIX = 'js-vmzhb-rz-station__';

const STR_NO_DATA = 'unbekannt';
const DAY_IN_MS = 24 * 60 * 60 * 1000;

Highcharts.setOptions(config.HIGHCHARTS_OPTIONS);

const defaultStationOptions = {
	chart: {
		type: 'column',
		height: 240,
		backgroundColor: 'transparent',
	},
	legend: false,
	tooltip: {xDateFormat: '%A, %d.%m.%Y'},
};

function htmlIfExists(container, classSuffix, html) {
	const element = container.querySelector('.' + CLASS_PREFIX + classSuffix);

	if (element) {
		element.innerHTML = html;
	}
}

export function renderStation(container, stationId) {
	const lastStationId = container.getAttribute('js-vmzhb-rz-last-station');
	if (lastStationId === stationId) {
		return;
	}
	container.setAttribute('js-vmzhb-rz-last-station', stationId);

	queryJson(
		{
			action: 'Sums',
			stationId: stationId,
		},
		response => {
			htmlIfExists(container, 'sum-all',
				response.total && response.total.value ? formatPrettyNumber(response.total.value) : STR_NO_DATA
			);

			htmlIfExists(container, 'sum-average',
				response.dailyAverage && response.dailyAverage.value ? formatPrettyNumber(response.dailyAverage.value) : STR_NO_DATA
			);

			{
				let html = STR_NO_DATA;

				if (response.lastDays && response.lastDays.values && response.lastDays.values.length) {
					const lastDay = response.lastDays.values.pop();
					let lastDayDate = new Date(lastDay[0]);
					lastDayDate = lastDayDate.getDate() + '.' + (1 + lastDayDate.getMonth()) + '.';
					htmlIfExists(container, 'sum-day-date', lastDayDate || ' - ');

					if (lastDay[1]) {
						html = formatPrettyNumber(lastDay[1]);
					}
				}

				htmlIfExists(container, 'sum-day', html);
			}
		}
	);

	const el7 = container.querySelector(`.${CLASS_PREFIX}chart--lastdays7`);
	const el30 = container.querySelector(`.${CLASS_PREFIX}chart--lastdays30`);
	if (el7 || el30) {
		queryJson(
			{
				action: 'LastValues',
				resolution: 'daily',
				stationId: stationId,
			},
			response => {
				const data = response[stationId];

				if (el7) {
					Highcharts.chart(el7, {
						...defaultStationOptions,
						xAxis: {
							min: (+new Date(data.toDateTime)) - (7 * DAY_IN_MS),
							max: (+new Date(data.toDateTime)) - DAY_IN_MS + 1000,
						},
						series: [
							{
								name: config.STR_SERIES,
								data: data.values.slice(0).map(mapChartEntry),
							},
						],
					});
				}

				if (el30) {
					Highcharts.chart(el30, {
						...defaultStationOptions,
						xAxis: {
							min: (+new Date(data.fromDateTime)),
							max: (+new Date(data.toDateTime)) - DAY_IN_MS + 1000,
						},
						series: [
							{
								name: config.STR_SERIES,
								data: data.values.slice(0).map(mapChartEntry),
							},
						],
					});
				}
			}
		);
	}

	const elMonths = container.querySelector(`.${CLASS_PREFIX}chart--lastmonths`);
	if (elMonths) {
		queryJson(
			{
				action: 'LastValues',
				resolution: 'monthly',
				stationId: stationId,
			},
			response => {
				const data = response[stationId];

				Highcharts.chart(elMonths, {
					...defaultStationOptions,
					xAxis: {
						min: (+new Date(data.fromDateTime)),
						max: (+new Date(data.toDateTime)) - DAY_IN_MS + 1000,
						labels: {enabled: false},
					},
					tooltip: {xDateFormat: '%B %Y'},
					series: [
						{
							name: config.STR_SERIES,
							data: data.values.slice(0).map(mapChartEntry),
						},
					],
				});
			}
		);
	}
}

export function render() {
	const containers = [].slice.call(window.document.querySelectorAll('.js-vmzhb-rz-station'));

	containers.forEach(container => {
		const stationId = container.getAttribute('data-vmzhb-rz-station-id');
		renderStation(container, stationId);
	});
}
