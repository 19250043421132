//import 'preact/debug';
import 'focus-visible/dist/focus-visible';
import piwik from '@neonaut/simplejs/piwik';

import * as ajaxify from './modules/ajax-load-page';

import {renderMapsightApp} from './mapsight-hb';

import './modules/a11y/tab-page-section-navigation';
import {closeNavigation} from './modules/mobile-navigation';
import './modules/main-nav';
import './modules/notifications';
import './modules/toggle-content';
import './modules/scrolled';
import './modules/scroll-me';
import './modules/page';

import {render as renderLightbox} from './modules/lightbox';
import {render as renderVbaA1A27} from './modules/vba-a1-a27';
import {render as renderRadzaehlerStation} from './modules/radzaehler/station';
import {render as renderRadzaehlerOverview} from './modules/radzaehler/overview';
import {render as renderRadzaehlerWizard} from './modules/radzaehler/wizard';
import {render as renderSrcRefresh} from './modules/src-refresh';
import {render as renderSkipLinkMapsightContent} from './modules/skip-link-mapsight-content';

const PIWIK_URL = '//stats.vmz.bremen.de/';
const PIWIK_SITE_ID = 2;
const PIWIK_DOMAINS = ['*.vmz.bremen.de', '*.vmz-bremen.de', '*.www.vmz-bremen.de', '*.www.vmz.bremen.de' /*, 'p-hb'*/];
piwik.init(PIWIK_URL, PIWIK_SITE_ID, PIWIK_DOMAINS);

//piwik.init(PIWIK_URL, 5, PIWIK_DOMAINS); //  debug für p-hb und andere localhost sites

function renderPartialChange() {
	renderVbaA1A27();
	renderRadzaehlerOverview();
	renderRadzaehlerStation();
	renderRadzaehlerWizard();
	renderSrcRefresh();
	renderLightbox();
}


function renderAll() {
	renderMapsightApp({
		onPartialContentChanged: function renderMapsightPartialChange() {
			renderRadzaehlerStation();
			renderSrcRefresh();
			renderLightbox();
		},
	});
	renderPartialChange();
	renderSkipLinkMapsightContent();
}

// enable for prototype preset swapping
// window.triggerReload wird nur vom Prototyp-HTML benutzt
//// eslint-disable-next-line piggyback/no-restricted-global-extend
//window.triggerReload = () => globalEventHub.emit(EVENT_PAGE_CHANGED);

// CSS hook
window.addEventListener(
	'load',
	() => window.document.documentElement.classList.add('window-load-done'),
	{once: true}
);

ajaxify.hub.on(ajaxify.PAGE_LOADING_AFTER_EVENT_NAME, () => {
	renderAll();
	closeNavigation();

	// scroll to top
	window.requestAnimationFrame(() => {
		document.documentElement.scrollTop = document.body.scrollTop = 0;
	});
});

ajaxify.init();
ajaxify.ajaxifyLinks(
	window.document.body,
	'.vmzhb-main-navigation__item a,' +
	'.vmzhb-main-navigation__sub-item a,' +
	'.vmzhb-fhb-banner a,' +
	'.ms3-marginal .ce-menu a'
);

// initial render
renderAll();
