import throttle from 'lodash/throttle';

let isScrolled = false;

function onScroll() {
	const scrollY = window.pageYOffset || document.scrollTop || 0;
	isScrolled = scrollY > 50;
	document.body.classList[isScrolled ? 'add' : 'remove']('vmzhb-site--scrolled');
}

window.addEventListener(
	'scroll',
	throttle(onScroll, 100),
	{passive: true}
);

onScroll();

export default function isWindowScrolled() {
	return isScrolled;
}
