import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import pick from 'lodash/pick';

import {batchActions} from 'redux-batched-actions';
import ReactModal from 'react-modal';

import {observeState} from '@neonaut/lib-redux/observe-state';
import getJSONContentFromDom from '@neonaut/simplejs/get-json-content';
import setDocumentScroll from '@neonaut/simplejs/dom/set-document-scroll';

import {defaultNoScrollHandler, defaultNoScrollObserveSelector} from '@mapsight/ui/plugins/browser/no-scroll';
import browserEmbed from '@mapsight/ui/embed/browser';
import {setAppTitle, setListVisible, setMapVisible, setTimeFilterVisible} from '@mapsight/ui/store/actions';
import {FEATURE_SELECTIONS, MAP, TIME_FILTER} from '@mapsight/ui/config/constants/controllers';
import {FEATURE_SELECTION_HIGHLIGHT, FEATURE_SELECTION_SELECT} from '@mapsight/ui/config/feature/selections';

import {mergeAll} from '@mapsight/core/lib/base/actions';
import {deselectAll} from '@mapsight/core/lib/feature-selections/actions';

import {closeNavigation, getIsNavigationOpen, subscribeNavigationOpenChange} from '../modules/mobile-navigation';
import baseConfiguration from './config/base';

import embed from './embed';

ReactModal.setAppElement('.ms3-app');

const resetState = {
	[MAP]: {
		view: baseConfiguration[MAP].view,
		layers: mapValues(baseConfiguration[MAP].layers, layer => pick(layer, [
			'options.visible',
			'options.source.options',
			'metaData.visibleInLayerSwitcher',
		])),
	},
	[TIME_FILTER]: {
		options: {
			allowEmpty: false,
		},
	},
};

let store = null;

function getTitleFromDom() {
	const title = typeof window !== 'undefined' && window.document && typeof window.document.title === 'string' && window.document.title || '';
	return title.replace(' | VMZ Bremen', '');
}

export function renderMapsightApp({onPartialContentChanged, hook}) {
	const preset = getJSONContentFromDom('js-ms3-preset') || {};
	const presetActions = [
		setMapVisible(!!preset.map),
		setListVisible(!!preset.list),
		setTimeFilterVisible(!!preset.timeFilter),
		setAppTitle(getTitleFromDom()),
	];

	// TODO: force ms to render once with the hydration state by delaying all dispatches or something
	if (store) {
		store.dispatch(batchActions([
			deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_HIGHLIGHT),
			deselectAll(FEATURE_SELECTIONS, FEATURE_SELECTION_SELECT),
			mergeAll(merge(
				{},
				resetState,
				preset
			)),
			...presetActions,
		]));
		return;
	}

	browserEmbed(
		document.getElementById('js-ms3'),
		embed(preset, {
			isBrowser: true,
			hook: (ctx) => {
				store = ctx.store;

				// no-scroll if on mobile and mapsight-ui wants it
				let currentIsMainNavigationOpen = getIsNavigationOpen();
				subscribeNavigationOpenChange(isMainNavigationOpen => {
					currentIsMainNavigationOpen = isMainNavigationOpen;
					defaultNoScrollHandler(store.getState(), currentIsMainNavigationOpen);
				});
				observeState(store, defaultNoScrollObserveSelector, () => {
					const isNoScroll = defaultNoScrollHandler(store.getState(), currentIsMainNavigationOpen);
					if (isNoScroll) {
						// zurückscrollen, bei Wechsel der Ansicht oder dem Schließen der Navigation,
						// falls vorher gescrollt worden ist und es jetzt verboten ist.
						setDocumentScroll(0);
					}
					closeNavigation();
				});
				defaultNoScrollHandler(store.getState(), currentIsMainNavigationOpen);

				store.dispatch(batchActions(presetActions));

				if (hook) {
					hook(ctx);
				}
			},
			onPartialContentChanged: onPartialContentChanged,
		}),
	);
}
