import * as config from '@mapsight/ui/config';
import createFeatureInteractions from '@mapsight/ui/config/map/featureInteractions';

import featureSources from './feature/feature-sources';
import mapLayers from './map/layers';
import mapView from './map/view';

export default {
	...config.map(mapLayers, mapView, undefined, true, createFeatureInteractions({deselectUncontrolledOnClick: ['preselect']})),
	...config.features(featureSources),
	...config.featureList('constructionWork'),
	...config.timeFilter(),
};
