const TOOLTIP_ID_PREFIX = 'vmzhb-vba-a1-a27__tooltip--';
const REFRESH_INTERVAL = 60 * 1000; // once per minute

export function render(mapElement) {
	const entries = [
		mapElement,
		document.getElementsByClassName('js-vmzhb-vba-a1-a27__traveltime-os')[0],
		document.getElementsByClassName('js-vmzhb-vba-a1-a27__traveltime-hh')[0],
		document.getElementById(`${TOOLTIP_ID_PREFIX}os_pkw_img`),
		document.getElementById(`${TOOLTIP_ID_PREFIX}os_lkw_img`),
		document.getElementById(`${TOOLTIP_ID_PREFIX}hh_pkw_img`),
		document.getElementById(`${TOOLTIP_ID_PREFIX}hh_lkw_img`),
	]
		.map((elem) => ({
			elem: elem,
			origSrc: elem.src,
		}));

	function update() {
		window.setTimeout(update, REFRESH_INTERVAL);

		const t = Date.now();
		entries.forEach(({elem, origSrc}) => {
			elem.src = `${origSrc}&t=${t}`;
		});
	}

	update();
}
