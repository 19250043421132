import EventEmitter from 'events';

import parseHtml from '@neonaut/simplejs/dom/parse-html';
import {trackPageView} from '@neonaut/simplejs/piwik';

import loadXhr from '../helpers/xhr';

import {updateMainNav} from './main-nav';

export const PAGE_CHANGING_CLASS_NAME = 'vmzhb--page-changing';
export const PAGE_LOADING_CLASS_NAME = 'vmzhb--page-loading';
export const PAGE_UPDATING_CLASS_NAME = 'vmzhb--page-updating';
export const PAGE_LEAVING_CLASS_NAME = 'vmzhb--page-leaving';
export const PAGE_UPDATED_CLASS_NAME = 'vmzhb--page-updated';

export const PAGE_LOADING_BEFORE_EVENT_NAME = 'vmzhbAjaxLoadPageBefore';
export const PAGE_LOADING_AFTER_EVENT_NAME = 'vmzhbAjaxLoadPageAfter';

export const PRESET_SELECTOR = '#js-ms3-preset';
export const PAGE_STYLE_SELECTOR = '#vmzhb-page-style';

const CONTENT_SELECTOR = '.js-vmzhb-ajax-variable-content';
const MARGINAL_SELECTOR = '.js-vmzhb-ajax-variable-marginal';

const NO_AJAXY_SELECTOR = '.no-ajaxy';
const LINK_SELECTOR = 'a:not(' + NO_AJAXY_SELECTOR + ')';

export const hub = new EventEmitter();

let currentXhr;
let delayTimeout;
let changedPage = false;

/**
 * TODO: Explain why we replace things
 *
 * @param {string} html original html
 * @returns {string} html resulting html
 */
function getDocumentHtml(html) {
	return String(html)
		.replace(/<!DOCTYPE[^>]*>/i, '')
		.replace(/<(html|head|body|title|meta|script)([\s>])/gi, '<div data-ajax-node="$1"$2')
		.replace(/<\/(html|head|body|title|meta|script)>/gi, '</div>')
		.trim();
}

function isLinkInternal(rootUrl, linkElement) {
	const url = linkElement.getAttribute('href') || '';
	return url.substring(0, rootUrl.length) === rootUrl || url.indexOf(':') === -1;
}

function documentTitleUpdateHandler(updateData) {
	const newTitle = updateData.newHead.querySelector('[data-ajax-node="title"]').textContent;

	/* eslint-disable no-empty */
	try {
		window.document.title = newTitle;
	} catch (e) {
	}
}

function bodyClassNameUpdateHandler(updateData) {
	window.document.body.className = updateData.newBody.className;
}

function htmlUpdateHandler(selector, sourceDom) {
	const target = window.document.querySelector(selector);
	const source = sourceDom.querySelector(selector);

	if (target && source) {
		target.innerHTML = source.innerHTML;
	}
}

function layoutReflowHandler(selector) {
	const target = window.document.querySelector(selector);

	if (target) {
		target.style.marginTop = '-1px';
		setTimeout(() => {
			target.style.marginTop = '0px';
		}, 10);
	}
}

function textUpdateHandler(selector, sourceDom) {
	const target = window.document.querySelector(selector);
	const source = sourceDom.querySelector(selector);

	if (target && source) {
		target.textContent = source.textContent;
	}
}

function updatePage(url, data) {
	window.document.documentElement.classList.remove(PAGE_UPDATED_CLASS_NAME);
	window.document.documentElement.classList.add(PAGE_UPDATING_CLASS_NAME);

	const newDocument = parseHtml(getDocumentHtml(data))[0];

	const updateData = {
		url: url,
		newDocument: newDocument,
		newHead: newDocument.querySelector('[data-ajax-node="head"]'),
		newBody: newDocument.querySelector('[data-ajax-node="body"]'),
	};

	documentTitleUpdateHandler(updateData);
	bodyClassNameUpdateHandler(updateData);
	htmlUpdateHandler(CONTENT_SELECTOR, updateData.newBody);
	layoutReflowHandler(CONTENT_SELECTOR);
	htmlUpdateHandler(MARGINAL_SELECTOR, updateData.newBody);
	updateMainNav(updateData.newBody.querySelector('#vmzhb-main-navigation'));
	textUpdateHandler(PRESET_SELECTOR, updateData.newBody);

	delayTimeout = window.setTimeout(() => {
		htmlUpdateHandler(PAGE_STYLE_SELECTOR, updateData.newHead);
	}, 300);

	hub.emit(PAGE_LOADING_AFTER_EVENT_NAME, updateData);
	trackPageView(url);

	window.document.documentElement.classList.remove(PAGE_UPDATING_CLASS_NAME);
	window.document.documentElement.classList.add(PAGE_UPDATED_CLASS_NAME);
}

function getRootUrl() {
	const {protocol, hostname, host, port} = window.document.location;
	return `${protocol}//${hostname || host}${port ? ':' + port : ''}/`;
}

export function changePage(url, title) {
	window.document.documentElement.classList.remove(PAGE_UPDATED_CLASS_NAME);
	window.document.documentElement.classList.add(PAGE_CHANGING_CLASS_NAME);
	changedPage = true;
	window.history.pushState(null, title, url);
	onLocationStateChange();
	window.document.documentElement.classList.remove(PAGE_CHANGING_CLASS_NAME);
}

// Ajaxify
export function ajaxifyLinks(element, selector = LINK_SELECTOR) {
	const rootUrl = getRootUrl();

	element.addEventListener('click', e => {
		const linkElement = e.target;
		if (!linkElement.matches(selector)) {
			return true;
		}

		// Continue as normal for external links and cmd clicks (and other mod keys...
		// for all non-iThingy users) etc
		if (
			e.which !== 1 || e.metaKey || e.altKey || e.ctrlKey || e.shiftKey ||
			!isLinkInternal(rootUrl, e.currentTarget) ||
			linkElement.matches(NO_AJAXY_SELECTOR)
		) {
			return true;
		}
		e.preventDefault();
		e.stopPropagation();

		const url = linkElement.getAttribute('href');
		const title = linkElement.getAttribute('title') || null;

		linkElement.blur();

		changedPage = false;
		hub.emit(PAGE_LOADING_BEFORE_EVENT_NAME, {url: url, title: title, link: linkElement});

		if (!changedPage) {
			changePage(url, title);
		}

		return false;
	});
}

function onLocationStateChange() {
	// FIXME: remove hash from href, not just ignore the url?!
	if (window.location.hash.length > 0) {
		return;
	}

	const url = location.href;

	// Set Loading
	window.document.documentElement.classList.add(PAGE_LOADING_CLASS_NAME);

	if (currentXhr) {
		currentXhr.abort();
	}

	if (delayTimeout) {
		clearTimeout(delayTimeout);
	}

	currentXhr = loadXhr(
		url,
		data => {
			window.document.documentElement.classList.remove(PAGE_LOADING_CLASS_NAME);

			try {
				updatePage(url, data);
			} catch (e) {
				console.warn(e);
				window.document.documentElement.classList.add(PAGE_LEAVING_CLASS_NAME);
				window.document.location.href = url;
			}
		},
		error => {
			window.document.documentElement.classList.remove(PAGE_LOADING_CLASS_NAME);

			if (error.statusText !== 'abort') {
				console.warn(error);
				window.document.documentElement.classList.add(PAGE_LEAVING_CLASS_NAME);
				window.document.location.href = url;
			}
		}
	);
}

export function init() {
	window.addEventListener('popstate', onLocationStateChange);
}
