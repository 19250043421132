import {memo, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import {translate} from "@mapsight/ui/helpers/i18n";
import {setOverlayModalVisible} from "@mapsight/ui/store/actions";

import {
	isOverlayModalVisibleSelector,
	isViewMobileOrMapOnlySelector,
} from "@mapsight/ui/store/selectors";
import Modal from "@mapsight/ui/components/modal";
import Attribution from "@mapsight/ui/components/map-overlay/attribution";
import Logo from "@mapsight/ui/components/map-overlay/logo";

import Legend from './Legend';

function MapOverlayModal() {
	const dispatch = useDispatch();

	const isExpanded = useSelector(isOverlayModalVisibleSelector);
	const isMobileOrMapOnly = useSelector(isViewMobileOrMapOnlySelector);

	const collapse = useCallback(() => {
		dispatch(setOverlayModalVisible(false));
	}, [dispatch]);

	return (
		<Modal
			isOpen={isExpanded}
			contentLabel={translate("ui.map-overlay.info.modal.label")}
			closeLabel={
				isMobileOrMapOnly
					? translate("ui.map-overlay.info.closeSources")
					: translate("ui.map-overlay.info.closeLegend")
			}
			headline={translate("ui.map-overlay.info.modal.headline")}
			onRequestClose={collapse}
		>
			<Attribution />
			<Legend />
			<Logo />
		</Modal>
	);
}

export default memo(MapOverlayModal);
