function focusMsUiContent(e) {
	// try to focus one of these if present and visible (FCFS)
	const selectorsInOrder = [
		// feature selection info:
		'.ms3-panel:not(.ms3-panel--empty) .ms3-feature-selection-info__wrapper', // not-hidden selection details in map (desktop,fullscreen,mapOnly)
		'.ms3-list__item--selected.ms3-list__item--has-details', // otherwise, selection details in list (mobile)
		// list:
		'.ms3-panel:not(.ms3-panel--empty) .ms3-list', // otherwise, not-hidden list in map (fullscreen)
		'.ms3-additional-container .ms3-list', // otherwise, list (desktop,mobile)
		// todo: handle mapOnly if no feature is selected (should this change view to mobile and then focus list?)
	];

	for (const selector of selectorsInOrder) {
		const el = document.querySelector(selector);
		if (el) {
			el.focus();
			e.preventDefault();
			break;
		}
	}
}

export function render() {
	document.querySelectorAll('.js-vmzhb-skip-to-mapsight-ui-content').forEach(linkEl => {
		linkEl.addEventListener('click', focusMsUiContent);
	});
}
