import {useSelector} from "react-redux";

import {visibleLayersWithLegendsSelector} from "@mapsight/core/lib/map/selectors";
import {MAP} from "@mapsight/ui/config/constants/controllers";

import {LayerLegend} from './legends';

const legendsSelector = (state) => visibleLayersWithLegendsSelector(state[MAP]);

function Legend() {
	const legends = useSelector(legendsSelector);

	return (
		<div className="vmzhb-legend">
			{legends && Object.entries(legends).map(([layerId, state]) => (
				<div key={layerId}>
					<h4>{state.metaData?.title || layerId}</h4>
					<LayerLegend layerId={layerId}/>
				</div>
			))}
		</div>
	)
}


export default Legend;
